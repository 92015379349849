import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

const ReportInsuranceTableRow = ({ data, name, number, showPeriodName, periodSelect, area, group, key, sliderVal, ix, sum = false }) => {
  const { t } = useTranslation();
  const months = t('monthsTab', { returnObjects: true });
  let rows;

  const valueColor = val => {
    if (val > 0) {
      return 'f-to-right f-plus';
    } else if (val < 0) {
      return 'f-to-right f-minus';
    } else {
      return 'f-to-right';
    }
  };

  if (data && data.length > 0) {
    rows = data.map((row, i) => {
      let p = null;

      if (row.period && row.period !== -1) {
        p = (periodSelect === "1") ? `${months[row.period - 1]} / ${row.year}` : `${row.period} / ${row.year}`;
      }

      return (
          <tr key={`tr_${i}`} className={(ix === 0 ? 't-top-border--strong' : '')}>
            <td className={`${sum ? 'f-sum' : ''} t-left-border`}>{area}</td>
            {sliderVal > 0 && <td className={`t-left-border ${sliderVal === 1 ? 't-right-border' : '' } ${sum ? 'f-sum' : ''} `}>{group}</td>}
            {sliderVal === 2 && <td className={`${sum ? 'f-sum' : ''} t-left-border`}>{number}</td>}
            {sliderVal === 2 && <td className={`${showPeriodName ? '' : 't-right-border'} ${sum ? 'f-sum' : ''}`}>{name}</td>}
            {showPeriodName && <td className={`${sum ? 'f-sum' : ''} t-right-border`}>{p}</td>}
            {sliderVal === 2 && <td>{row.insuranceCode}</td>}
            {sliderVal === 2 && <td className='t-right-border'>{row.insuranceName}</td>}
            <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={row.visits} thousandSeparator=" " displayType="text"/></td>
            <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={row.visitsM1Y} thousandSeparator=" " displayType="text"/></td>
            <td className={`t-right-border ${valueColor(row.visitsTrend)} ${sum ? 'f-sum' : ''}`}><NumericFormat value={row.visitsTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
            <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={row.turnover} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
            <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={row.turnoverM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
            <td className={`${valueColor(row.turnoverTrend)} ${sum ? 'f-sum' : ''} t-right-border`}><NumericFormat value={row.turnoverTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
            <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={row.turnoverPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
            <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={row.turnoverM1YPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
            <td className={`${valueColor(row.turnoverPerVisitTrend)} ${sum ? 'f-sum' : ''} t-right-border`}><NumericFormat value={row.turnoverPerVisitTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
          </tr>
      )
    });
  } else {
    let p = null;

    if (data.period && data.period !== -1) {
      p = (periodSelect === "1") ? `${months[data.period - 1]} / ${data.year}` : `${data.period} / ${data.year}`;
    }

    return (
        <tr key={`tr_${key}`}>
          <td className={`t-left-border ${sliderVal === 0 ? 't-right-border' : ''} ${sum ? 'f-sum' : ''}`}>{area}</td>
          {sliderVal > 0 && <td className={`t-left-border ${sliderVal === 1 ? 't-right-border' : ''} ${sum ? 'f-sum' : ''}`}>{group}</td>}
          {sliderVal === 2 && <td className={`${sum ? 'f-sum' : ''} t-left-border`}>{number}</td>}
          {sliderVal === 2 && <td className={`${showPeriodName ? '' : 't-right-border'} ${sum ? 'f-sum' : ''}`}>{name}</td>}
          {showPeriodName && <td className={`${sum ? 'f-sum' : ''} t-right-border`}>{p}</td>}
          {sliderVal === 2 && <td className='f-sum'></td>}
          {sliderVal === 2 && <td className='t-right-border f-sum'></td>}
          <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={data.visits} thousandSeparator=" " displayType="text"/></td>
          <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={data.visitsM1Y} thousandSeparator=" " displayType="text"/></td>
          <td className={`t-right-border ${valueColor(data.visitsTrend)} ${sum ? 'f-sum' : ''}`}><NumericFormat value={data.visitsTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
          <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={data.turnover} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
          <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={data.turnoverM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
          <td className={`${valueColor(data.turnoverTrend)} ${sum ? 'f-sum' : ''} t-right-border`}><NumericFormat value={data.turnoverTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
          <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={data.turnoverPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
          <td className={`${sum ? 'f-sum' : ''} f-to-right`}><NumericFormat value={data.turnoverM1YPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>
          <td className={`${valueColor(data.turnoverPerVisitTrend)} ${sum ? 'f-sum' : ''} t-right-border`}><NumericFormat value={data.turnoverPerVisitTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
        </tr>
    )
  }

  return rows;
};

const ReportInsuranceTable = ({ tableData, showPeriodName, periodSelect, sliderVal }) => {
  const { t } = useTranslation();
  let rows = [];

  if (sliderVal === 0) {
    rows = tableData.areas.map((area, i) => {
      return <ReportInsuranceTableRow data={area.summary} showPeriodName={showPeriodName} periodSelect={periodSelect} area={area.areaName} key={area.areaId} sliderVal={sliderVal} />
    });
  } else if (sliderVal === 1) {
    rows = tableData.areas.map(area => {
      let areaName = area.areaName;
      const groupsData = area.groups.map(group => {
        return <ReportInsuranceTableRow data={group.summary} showPeriodName={showPeriodName} periodSelect={periodSelect} area={areaName} group={group.groupName} key={group.groupId} sliderVal={sliderVal} />
      });
      return groupsData;
    })
  } else {
    rows = tableData.areas.map(area => {
      let areaName = area.areaName;
      let periods;
      const groupsData = area.groups.map(group => {
        let dealerGroup = group.groupName;
        periods = group.dealers.map((dealer, i) => {
          return <ReportInsuranceTableRow data={dealer.periods} name={dealer.dealerName} number={dealer.dealerNumber} showPeriodName={showPeriodName} periodSelect={periodSelect} area={areaName} group={dealerGroup} key={dealer.insuranceCode} sliderVal={sliderVal} ix={i} />
        });

        if (group.dealers.length > 1) {
          periods.push(<ReportInsuranceTableRow data={group.summary} name={t('sum')} number='' showPeriodName={showPeriodName} periodSelect={periodSelect} area='' group='' key={group.groupId} sliderVal={sliderVal} sum={true}/>)
        }

        return periods;
      });
      return groupsData;
    })
  }

  return (
      <table>
        <thead className="sticky">
        <tr>
          <th className='nowrap bg-default'>{t('area')}</th>
          {sliderVal > 0 && <th className='nowrap bg-default'>{t('dealerGroup')}</th>}
          {sliderVal === 2 && <th className='nowrap bg-default'>{t('dealerNumber')}</th>}
          {sliderVal === 2 && <th className='nowrap bg-default'>{t('dealerName')}</th>}
          {showPeriodName && <th className="nowrap bg-default">{t('period')}</th>}
          {sliderVal === 2 && <th className='nowrap bg-default'>Code</th>}
          {sliderVal === 2 && <th className='nowrap bg-default'>Name</th>}
          <th className='nowrap bg-visits'>{t('visitsY')}</th>
          <th className='nowrap bg-visits'>{t('visitsY-1')}</th>
          <th className='nowrap bg-visits' dangerouslySetInnerHTML={{__html: t('visitsYvs')}}></th>
          <th className='nowrap bg-turnover'>{t('turnoverY')}</th>
          <th className='nowrap bg-turnover'>{t('turnoverY-1')}</th>
          <th className='nowrap bg-turnover' dangerouslySetInnerHTML={{__html: t('turnoverYvs')}}></th>
          <th className='nowrap bg-basket'>{t('avBasketY')}</th>
          <th className='nowrap bg-basket'>{t('avBasketY-1')}</th>
          <th className='nowrap bg-basket' dangerouslySetInnerHTML={{__html: t('avBasketYvs')}}></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
  );
};

export default ReportInsuranceTable;
