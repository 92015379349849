import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import styledSC from "styled-components";
import Close from '../_icons/Close';
import { Context } from '../../commons/context';

const FiltersWrapper = styledSC.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  padding-left: 320px;
  transition: padding-left .2s ease-in-out;
  
  .hideSideBar & {
    padding-left: 20px;
  }
`;

const FiltersTitle = styledSC.span`
  position: relative;
  top: 4px;
  margin-right: 10px;
  font-size: 16px;
`;

const Filter = styledSC.div`
  display: flex;
  margin: 0 10px 5px 0;
  padding: 2px 8px 4px;
  font-size: 14px;
  line-height: 22px;
  background-color: ${props => props.theme.colors.bgLightGrayColor};
  border-radius: 8px;
`;

const IconContainer = styledSC.div`
  position: relative;
  top: 2px;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.grayDisabled};
  
  &:hover {
    cursor: pointer; 
  }
  
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #fff;
  }
`;

const Button = styledSC.span`
  margin: 0 10px 5px 0;
  padding: 2px 8px 4px;
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  line-height: 22px;
  background-color: ${props => props.theme.colors.grayDisabled};
  border-radius: 8px;
  
  &:hover {
    cursor: pointer;
  }
`;

const ReportCategoriesBar = () => {
  const { t } = useTranslation();
  const [data, setData] = useContext(Context);
  let catSelectedData;

  const removeHandler = (id) => {
    const newCategories = data.filters.categories.filter(c => c !== id);

    setData({
      type: 'SET_FILTERS',
      payload: {
        ...data.filters,
        categories: newCategories,
        changeCat: true,
      },
    });
  };

  const removeAllHandler = () => {
    setData({
      type: 'SET_FILTERS',
      payload: {
        ...data.filters,
        categories: [],
        changeCat: true,
      },
    });
  };

  catSelectedData = data.filters.categories.map(d => data.filters.categoriesData.filter(f => d === f.id));

  const choosenCategories = catSelectedData.map(c => <Filter key={c[0].id}>{c[0].name}<IconContainer onClick={() => removeHandler(c[0].id)}><Close /></IconContainer></Filter>);

  return (
      <FiltersWrapper id="catBar">
        {data.filters.categories.length > 0 &&
          <>
            <FiltersTitle><b>{t('categoriesSet')}:</b></FiltersTitle>
            {choosenCategories}
            <Button onClick={removeAllHandler}>{t('uncheckAll')}</Button>
          </>
        }
      </FiltersWrapper>
  );
};

export default ReportCategoriesBar;
