import { createContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const AuthContext = createContext();
const API_URL = process.env.REACT_APP_API_URL;

export const AuthContextProvider = ({ children }) => {
  const [loginLoader, setLoginLoader] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [user, setUser] = useState(() => {
    if (localStorage.getItem('tokens')) {
      let tokens = JSON.parse(localStorage.getItem('tokens'));
      return jwt_decode(tokens.token);
    }

    return '';
  });
  const [authToken, setAuthToken] = useState((e) => {
    if (localStorage.getItem('tokens')) {
      let tokens = JSON.parse(localStorage.getItem('tokens'));
      return tokens;
    }

    return '';
  });

  const navigate = useNavigate();

  const login = async (payload) => {
    setLoginLoader(true);
    setLoginError(false);
    await axios.post(`${API_URL}Users/Token`,
      payload
    ).then(response => {
      localStorage.setItem('tokens', JSON.stringify(response.data));
      setUser(jwt_decode(response.data.token));
      setAuthToken(JSON.parse(JSON.stringify(response.data)));
      navigate("/");
      setLoginError(false);
    }).catch(() => {
      setUser(false);
      setLoginError(true);
    }).finally(() => {
      setLoginLoader(false);
    });
  };

  const logout = () => {
    console.log('logout');
    localStorage.removeItem('tokens');
    setUser(false);
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ authToken, user, login, logout, loginLoader, loginError }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
