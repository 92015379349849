import React from 'react';
import styledSC from 'styled-components';
import PropTypes from "prop-types";

const Btn = styledSC.button`
  position: relative;
  width: 100%;
  margin-top: 25px;
  padding: 10px 0;
  font-family: 'NouvelRBold';
  font-size: 16px;
  text-transform: uppercase;
  background-color: ${props => props.theme.colors.rBrandColor};
  border: none;
  
  &:hover {
    cursor: pointer;
    opacity: .8;
  }
`;

const Icon = styledSC.div`
  position: absolute;
  top: 10px;
  right: 10px;
  margin-left: 10px;
`;

const CustomButton = ({icon, text, type, onClickFnc}) => {
  return (
      <Btn type={type} onClick={onClickFnc}>
        {text}
        {icon &&
          <Icon>
            {icon}
          </Icon>
        }
      </Btn>
  );
};

CustomButton.defaultProps = {
  icon: null,
  text: '',
  type: 'submit',
};

CustomButton.propTypes = {
  onClickFnc: PropTypes.func,
  icon: PropTypes.any,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CustomButton;
