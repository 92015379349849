import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadReport from '../_shared/DownloadReport';
import ReportTop20Data from './ReportTop20Data';
import ReportFiltersBar from './ReportFiltersBar';
import ReportCategoriesBar from './ReportCategoriesBar';
import SideBar from '../_shared/SideBar';
import TitleContainer from '../_shared/TitleContainer';

const ReportTop20 = () => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState([]);
  const [queryFilters, setQueryFilters] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(true);

  return (
      <div className='page-wrapper'>
        <SideBar setQueryFilters={setQueryFilters} isLoading={isLoading} filtersData={filtersData} setFiltersData={setFiltersData} cumulativeField={true} cumulativeFieldValue={true} categories={true} cumulativeDisabled={true} />
        <TitleContainer text={t('reportTop20')}>
          <DownloadReport disabled={noData} reportType={5} />
        </TitleContainer>
        <ReportFiltersBar filtersData={filtersData} queryFilters={queryFilters} />
        <ReportCategoriesBar />
        <ReportTop20Data queryFilters={queryFilters} isLoading={isLoading} setIsLoading={setIsLoading} noData={noData} setNoData={setNoData} />
      </div>
  );
};

export default ReportTop20;
