import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import DownloadReport from '../_shared/DownloadReport';
import ReportInsuranceDealerData from './ReportInsuranceDealerData';
import ReportFiltersBar from './ReportFiltersBar';
import SideBar from '../_shared/SideBar';
import TitleContainer from '../_shared/TitleContainer';

const ReportInsuranceDealer = () => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState([]);
  const [queryFilters, setQueryFilters] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(true);

  return (
      <div className='page-wrapper'>
        <SideBar setQueryFilters={setQueryFilters} isLoading={isLoading} filtersData={filtersData} setFiltersData={setFiltersData} cumulativeField={false} cumulativeFieldValue={true} cumulativeDisabled={true} timePeriod={false} />
        <TitleContainer text={t('reportInsuranceDealer')}>
          <DownloadReport disabled={noData} reportType={'InsuranceByDealer'} />
        </TitleContainer>
        <ReportFiltersBar filtersData={filtersData} queryFilters={queryFilters} noTimePeriod={true} noInTotal={true} />
        <ReportInsuranceDealerData isLoading={isLoading} setIsLoading={setIsLoading} noData={noData} setNoData={setNoData} />
      </div>
  );
}

export default ReportInsuranceDealer;