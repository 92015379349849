import * as React from 'react';

function SvgDownload(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.5 17.016h5.58c-.886-1.128-1.628-2.16-2.706-3.84l.815-.576c.79 1.272 1.173 1.968 1.844 2.952V6h.981v9.528c.67-.984 1.078-1.656 1.868-2.952l.791.6c-1.078 1.68-1.82 2.712-2.707 3.84H18.5V18H6.5v-.984z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}

export default SvgDownload;
