import * as React from 'react';

function SvgChevronLeft(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M15.611 18.15C12.376 16.032 9.98 14.31 7 11.913v-.816C9.98 8.7 12.376 6.978 15.611 4.86l.559.815c-2.98 1.885-5.283 3.537-8.076 5.841a76.964 76.964 0 008.076 5.819l-.559.815z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}

export default SvgChevronLeft;
