import React from 'react';
import styledSC from 'styled-components';
import ChevronLeftIcon from '../_icons/ChevronLeft';
import SidebarIcon from '../_icons/Sidebar';

const NavTitle = styledSC.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 5px 20px;
  border-bottom:  1px solid ${props => props.theme.colors.gray};
  font-size: 16px;
  font-family: 'NouvelRBold';
`;

const IconContainer = styledSC.div`
  &:hover {
    cursor: pointer;
  }
`;

const SecondIcon = styledSC.div`
  display: inline-block;
  margin-left: -14px;
`;

const SidebarIconContainer = styledSC.div`
  position: absolute;
  right: -40px;
  top: 20px;
  display: none;
  
  &:hover {
    cursor: pointer;
  }
  
  .hideSideBar & {
    display: block;
  }
`;

const ProjectTitle = ({fnc}) => {
  return (
    <NavTitle>
      B2C Reports
      <IconContainer onClick={fnc}>
        <ChevronLeftIcon/>
        <SecondIcon>
          <ChevronLeftIcon/>
        </SecondIcon>
      </IconContainer>
      <SidebarIconContainer onClick={fnc}>
        <SidebarIcon />
      </SidebarIconContainer>
    </NavTitle>
  );
};

export default ProjectTitle;
