import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '../_icons/Add';
import CategoriesAddForm from './CategoriesAddForm';
import CategoriesData from './CategoriesData';
import CustomButtonEmpty from '../_shared/CustomButtonEmpty';
import SideBar from '../_shared/SideBar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TitleContainer from '../_shared/TitleContainer';

const CategoriesPage = () => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [refreshCategories, setRefreshCategories] = useState(false);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setRefreshCategories(true);
  };

  return (
      <div className='page-wrapper'>
        <SideBar filters={false} />
        <TitleContainer text={t('categories')}>
          <CustomButtonEmpty text={t('addCategory')} icon={<AddIcon />} onClickFnc={openDrawer} />
        </TitleContainer>
        <CategoriesData refresh={refreshCategories} setRefreshFnc={setRefreshCategories} />
        <SwipeableDrawer
            anchor='right'
            open={drawerOpen}
            onClose={closeDrawer}
            onOpen={openDrawer}
        >
          <CategoriesAddForm />
        </SwipeableDrawer>
      </div>
  );
};

export default CategoriesPage;
