import * as React from 'react';

function SvgArrowRight(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20 11.912c-2.793 2.257-5.167 3.98-8.611 6.237l-.605-.814c2.7-1.723 4.77-3.166 7.61-5.33H4.5v-1h13.94c-2.838-2.165-4.91-3.608-7.61-5.33l.559-.815c3.444 2.257 5.818 3.98 8.611 6.237v.815z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}

export default SvgArrowRight;
