import * as React from 'react';

function SvgDelete(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.998 17.009h.982v-9h-.982v9zm-1.99 0h.983v-9h-.982v9zM11.003 6.04h2.996c-.048-.936-.575-1.056-1.486-1.056-.958 0-1.51.144-1.51 1.056zM10.02 17.01h.982v-9h-.982v9zm-2.013.288V8.008h.982v9.146c0 .623.24.887.863.887h5.296c.623 0 .863-.264.863-.887V8.008h.982v9.29c0 1.152-.551 1.704-1.701 1.704H9.708c-1.15 0-1.701-.552-1.701-1.704zM10.02 6.04c.024-1.416.886-2.04 2.492-2.04 1.606 0 2.468.624 2.468 2.04H18V7H7v-.96h3.02z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}

export default SvgDelete;
