import React from 'react';
import styledSC from 'styled-components';
import LoginForm from './LoginForm';

const Container = styledSC.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
`;

const IconContainer = styledSC.div`
  flex-grow: 0;
  flex-basis: 50%;
  width: 50%;
  margin: 0;
  background-image: url(./assets/svg/Key.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${props => props.theme.colors.bgLightGrayColor};
`;

const LoginPage = () => {
  return (
      <Container>
        <IconContainer />
        <LoginForm />
      </Container>
  );
};

export default LoginPage;
