import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportFiltersBar from './ReportFiltersBar';
import SideBar from '../_shared/SideBar';
import TitleContainer from '../_shared/TitleContainer';
import RepoertCrmData from './ReportCrmData';
import DownloadReport from '../_shared/DownloadReport';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ImportCrm from './ImportCrm';
import DownloadIcon from '../_icons/Download';
import CustomButtonEmpty from "../_shared/CustomButtonEmpty";
import styledSC from "styled-components";

const DIcon = styledSC(DownloadIcon)`
  transform: rotate(180deg);
`;

const ReportCrm = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [queryFilters, setQueryFilters] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(true);
  const [importDrawerOpen, setImportDrawerOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const importOpenDrawer = () => {
    setImportDrawerOpen(true);
  };

  const importCloseDrawer = () => {
    setImportDrawerOpen(false);
  };

  return (
      <div className='page-wrapper'>
        <SideBar setQueryFilters={setQueryFilters} isLoading={isLoading} filtersData={filtersData} setFiltersData={setFiltersData} cumulativeField={false} cumulativeFieldValue={true} onlyDate={true} />
        <TitleContainer text={t('reportCrm')}>
          <CustomButtonEmpty text={t('importReport')} icon={<DIcon />} onClickFnc={importOpenDrawer} />
          <DownloadReport disabled={noData} reportType='crmCampaigns' />
        </TitleContainer>
        <ReportFiltersBar filtersData={filtersData} queryFilters={queryFilters} onlyDate={true} />
        <RepoertCrmData queryFilters={queryFilters} isLoading={isLoading} setIsLoading={setIsLoading} data={data} setData={setData} noData={noData} setNoData={setNoData} refreshTable={refreshTable} setRefreshTable={setRefreshTable} />
        <SwipeableDrawer
            anchor='right'
            open={importDrawerOpen}
            onClose={importCloseDrawer}
            onOpen={importOpenDrawer}
        >
          <ImportCrm data={data} closeFnc={importCloseDrawer} setRefreshTable={setRefreshTable} />
        </SwipeableDrawer>
      </div>
  );
};

export default ReportCrm;
