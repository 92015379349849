import React, {useContext, useEffect, useState} from 'react';
import styledSC from "styled-components";
import CustomScrollbars from '../_shared/CustomScrollbars';
import JwtInterceptor from '../_shared/JwtInterceptors';
import Loader from '../_shared/Loader';
import NoData from '../_shared/NoData';
import ReportVisitsTurnoverCountryTable from './ReportVisitsTurnoverCountryTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography/Typography";
import Switch from '@mui/material/Switch';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Context } from '../../commons/context';
import {styled} from "@mui/material";
import Stack from "@mui/material/Stack/Stack";
import AuthContext from '../_shared/AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

ChartJS.register(ArcElement, Tooltip, Legend);

const FloatContainerSwitch = styledSC.div`
  position: absolute;
  top: 10px; 
  right: 40px;
  z-index: 1;
`;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#efdf00',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#efdf00',
    boxSizing: 'border-box',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box sx={{ p: 3 }}>
              {children}
            </Box>
        )}
      </div>
  );
};

const ReportVisitsTurnoverCountryData = ({ queryFilters, isLoading, setIsLoading, data, setData, noData, setNoData }) => {
  const { logout } = useContext(AuthContext);
  const [dataContext] = useContext(Context);
  const [value, setValue] = useState(0);
  const [rData, setRData] = useState([]);
  const [eur, setEur] = useState(false);
  const [error, setError] = useState(false);
  const scrollbarHeight = document.getElementById('filtersBar') ? window.innerHeight - (230 + document.getElementById('filtersBar').offsetHeight) : window.innerHeight - 170;

  useEffect(() => {
    const getData = async () => {
      let queryF = dataContext.filters.queryFilters;

      setIsLoading(true);
      setError(false);

      if (!queryF || queryF === '') {
        const now = new Date();
        const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const endDate = new Date(now.setDate(0));
        const startDateQuery = `${startDate.getFullYear()}-${startDate.getMonth()+1}-01`;
        const endDateQuery = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;

        queryF = `StartDate=${startDateQuery}&EndDate=${endDateQuery}&Period=week`;
      }

      JwtInterceptor
          .get(`${API_URL}Data/VisitsTurnoverCountryReport?${queryF}`)
          .then((result) => {
            setData(result.data);
            setIsLoading(false);

            if (result.data.length > 0) {
              setNoData(false);
            } else {
              setNoData(true);
            }

            setRData(result.data);
            setValue(0);
          }).catch(error => {
            console.log('Country Error', error);
            setIsLoading(false);
            setNoData(true);
            setError(true);
            if (error.response.status === 400) logout();
          });
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.filters.queryFilters]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const localCurrencyHandler = e => {
    setEur(e.target.checked);
  };

  const TabsHeader = rData.map((d, i) => <Tab label={d.country} key={`tab_${i}`} />);
  const TabsContent = rData.map((d, i) => {
    return(
        <TabPanel value={value} index={i} key={`tc_${i}`}>
          <CustomScrollbars style={{width: 'auto', height: scrollbarHeight}}>
            <ReportVisitsTurnoverCountryTable tableData={d} eur={eur} showPeriodName={!dataContext.filters.cumulative} periodSelect={dataContext.filters.period} />
          </CustomScrollbars>
        </TabPanel>
    )
  });

  return (
      <div className='page-wrapper'>
        {isLoading ? <Loader /> : (
          <div>
            {noData ? <NoData error={error} /> : (
              <Box sx={{ width: '100vw', height: '100vh' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <FloatContainerSwitch>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Waluta lokalna</Typography>
                      <AntSwitch defaultChecked={eur} checked={eur} inputProps={{ 'aria-label': 'ant design' }} onChange={localCurrencyHandler} />
                      <Typography>Euro</Typography>
                    </Stack>
                  </FloatContainerSwitch>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {TabsHeader}
                  </Tabs>
                </Box>
                {TabsContent}
              </Box>
            )}
          </div>
        )}
      </div>
  );
};

export default ReportVisitsTurnoverCountryData;
