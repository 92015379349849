import React, {useContext, useEffect, useState} from 'react';
import styledSC from 'styled-components';
import CustomScrollbars from '../_shared/CustomScrollbars';
import JwtInterceptor from '../_shared/JwtInterceptors';
import Loader from "../_shared/Loader";
import NoData from '../_shared/NoData';
import ReportVisitsKpiTable from './ReportVisitsKpiTable';
import { Context } from '../../commons/context';
import AuthContext from '../_shared/AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

const DataWrapper = styledSC.div`
  width: calc(100vw - 340px);
  margin-left: 320px;
  transition: margin-left .2s ease-in-out, width .2s ease-in-out;
    
  .hideSideBar & {
    margin-left: 20px;
    width: calc(100vw - 40px);
  }
`;

const ReportVisitsKpiData = ({ queryFilters, isLoading, setIsLoading, noData, setNoData }) => {
  const { logout } = useContext(AuthContext);
  const [dataContext] = useContext(Context);
  const [dataPl, setDataPl] = useState([]);
  const [dataCz, setDataCz] = useState([]);
  const [dataSk, setDataSk] = useState([]);
  const [error, setError] = useState(false);
  const scrollbarHeight = document.getElementById('filtersBar') ? window.innerHeight - (160 + document.getElementById('filtersBar').offsetHeight) : window.innerHeight - 170;

  useEffect(() => {
    const getData = async () => {
      let queryF = dataContext.filters.queryFilters;

      setIsLoading(true);
      setError(false);

      if (!queryF || queryF === '') {
        const now = new Date();
        const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const endDate = new Date(now.setDate(0));
        const startDateQuery = `${startDate.getFullYear()}-${startDate.getMonth()+1}-01`;
        const endDateQuery = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;

        queryF = `StartDate=${startDateQuery}&EndDate=${endDateQuery}&Period=week`;
      }

      JwtInterceptor
        .get(`${API_URL}Data/VisitsKpiReport?${queryF}`)
        .then((result) => {
          setIsLoading(false);

          if (result.data.length > 0) {
            setNoData(false);
          } else {
            setNoData(true);
          }

          setDataPl(result.data[0]);
          setDataCz(result.data[1]);
          setDataSk(result.data[2]);
        }).catch(error => {
          console.log('Country Error', error);
          setIsLoading(false);
          setNoData(true);
          setError(true);
          if (error.response.status === 400) logout();
      });
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.filters.queryFilters]);

  return (
      <div className='page-wrapper'>
        {isLoading ? <Loader /> : (
            <div>
              {noData ? <NoData error={error} /> : (
                  <DataWrapper>
                      <CustomScrollbars style={{ width: 'auto', height: scrollbarHeight }}>
                        {dataPl && <ReportVisitsKpiTable tableData={dataPl} title="PL" showPeriodName={!dataContext.filters.cumulative} periodSelect={dataContext.filters.period} />}
                        {dataCz && <ReportVisitsKpiTable tableData={dataCz} title="CZ" showPeriodName={!dataContext.filters.cumulative} periodSelect={dataContext.filters.period} />}
                        {dataSk && <ReportVisitsKpiTable tableData={dataSk} title="SK" showPeriodName={!dataContext.filters.cumulative} periodSelect={dataContext.filters.period} />}
                      </CustomScrollbars>
                  </DataWrapper>
              )}
            </div>
        )}
      </div>
  );
};

export default ReportVisitsKpiData;
