function StylesTextField() {
  return(`
  width: 100%;
  margin: 15px 0;
  color: #333;
  font-family: 'NouvelRRegular';
  
  & label {
    font-family: 'NouvelRRegular';
    color: #333;
    
    &.Mui-focused {
      color: #333;
    }
  }
  
  & input {
    font-family: 'NouvelRRegular';
    color: #333;
  }
  
  & .MuiInput-underline:after {
    border-bottom: 1px solid #333;
  }
  
  & .MuiInput-underline:before {
    border-bottom: 1px solid #333 !important;
  }
`)};

export default StylesTextField
