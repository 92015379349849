import React from 'react';
import styledSC from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const Wrapper = styledSC.div`
  position: absolute;
  top: -100px;
  left: 150px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left .2s ease-in-out;
  
  .hideSideBar & {
    left: 0;
  }
`;

function Loader() {
  return (
      <Wrapper>
        <CircularProgress thickness={7.2} size={100} style={{'color': '#efdf00'}} />
      </Wrapper>
  );
}

export default Loader;
