import React, { useReducer } from 'react';
import { Route, Routes } from "react-router-dom";
import Theme from './commons/theme';
import Layout from './components/_shared/Layout';
import CategoriesPage from './components/categories-page/CategoriesPage';
import LoginPage from './components/login-page/LoginPage';
import ReportInsurance from "./components/reports/ReportInsurance";
import ReportInsuranceDealer from "./components/reports/ReportInsuranceDealer";
import ReportVisitsTurnoverCountry from './components/reports/ReportVisitsTurnoverCountry';
import ReportVisitsTurnoverDealer from './components/reports/ReportVisitsTurnoverDealer';
import ReportVisitsTurnoverVehicle from './components/reports/ReportVisitsTurnoverVehicle';
import ReportVisitsKpi from './components/reports/ReportVisitsKpi';
import ReportTop20 from './components/reports/ReportTop20';
import ReportCrm from './components/reports/ReportCrm';
import { AuthContextProvider } from "./components/_shared/AuthContext";
import { DataReducer, DefaultData } from './commons/data-reducer';
import { Context } from './commons/context';

function App() {
  const [data, setData] = useReducer(DataReducer, DefaultData);

  return (
    <Theme>
      <AuthContextProvider>
        <Context.Provider value={[data, setData]}>
          <Layout>
            <Routes>
              <Route path="/" element={<ReportVisitsTurnoverCountry/>} />
              <Route path="/visitsturnoverdealer" element={<ReportVisitsTurnoverDealer/>} />
              <Route path="/visitsturnovervehicle" element={<ReportVisitsTurnoverVehicle/>} />
              <Route path="/visitskpi" element={<ReportVisitsKpi/>} />
              <Route path="/top20" element={<ReportTop20/>} />
              <Route path="/crm" element={<ReportCrm/>} />
              <Route path="/categories" element={<CategoriesPage/>} />
              <Route path="/insurance" element={<ReportInsurance />} />
              <Route path="/insurancebydealer" element={<ReportInsuranceDealer />} />
              <Route path="/login" element={<LoginPage />} />
            </Routes>
          </Layout>
        </Context.Provider>
      </AuthContextProvider>
    </Theme>
  );
}

export default App;
