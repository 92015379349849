import React from 'react';
import styledSC from 'styled-components';
import PropTypes from "prop-types";

const Btn = styledSC.a`
  display: flex;
  align-items: center;
  padding: 8px 32px;
  font-family: 'NouvelRBold';
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.black};
  transition: 0.3s;
  
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.bgLightGrayColor};
  }
  
  &.disabled {
    color: ${props => props.theme.colors.grayDisabled};
    border-color: ${props => props.theme.colors.grayDisabled};
  }
  
  &.text {
    border: none;
    text-transform: none;
    justify-content: center;
    margin-top: 10px;
    font-family: 'NouvelRRegular';
    
    &:hover {
      text-decoration: underline;
    } 
  }
`;

const Icon = styledSC.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const CustomButtonEmpty = ({ href, icon, onClickFnc, text, disabled, variant }) => {
  let cls = '';

  const clickHandler = () => {
    if (disabled) return;

    onClickFnc();
  };

  if (disabled) cls += ' disabled';
  if (variant) cls +=  ` ${variant}`;

  return (
      <Btn onClick={clickHandler} className={cls}>
        {text}
        {icon &&
        <Icon>
          {icon}
        </Icon>
        }
      </Btn>
  );
};

CustomButtonEmpty.defaultProps = {
  disabled: false,
  href: '#',
  icon: null,
  onClickFnc: null,
  text: '',
  variant: '',
};

CustomButtonEmpty.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.any,
  onClickFnc: PropTypes.func,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default CustomButtonEmpty;
