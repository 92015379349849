import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../../commons/context'
import styledSC from "styled-components";
import CloseIcon from '../_icons/Close';
import PageTitle from "../_shared/PageTitle";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";

const Container = styledSC.div`
  width: 50vw;
  padding: 20px;
`;

const Subtitle = styledSC.span`
  display: block;
  margin: 20px 0;
  font-family: NouvelRBold;
  font-size: 16px;
`;

const Close = styledSC(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 10px;
  padding: 10px;
  
  &:hover {
    cursor: pointer;
  }
`;

const ChartLineConfig = ({ labels, dataSet1, dataSet1Title, dataSet2, dataSet2Title, dataSet3, dataSet3Title, dataSet4, dataSet4Title, dataSet5, dataSet5Title, dataSet6, dataSet6Title, dataSet7, dataSet7Title, dataSet8, dataSet8Title, period }) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        title: {
          display: true,
          text: `Okres: ${period}`
        }
      },
      y: {
        title: {
          display: false,
          text: 'Wartości'
        }
      },
    },
  };

  const datasetTab = [];

  if (dataSet1) {
    datasetTab.push({
      label: dataSet1Title,
      data: dataSet1,
      borderColor: '#f8dcaa',
      backgroundColor: '#f8dcaa',
    });
  }
  if (dataSet2) {
    datasetTab.push({
      label: dataSet2Title,
          data: dataSet2,
        borderColor: '#e34234',
        backgroundColor: '#e34234',
    });
  }
  if (dataSet3) {
    datasetTab.push({
      label: dataSet3Title,
      data: dataSet3,
      borderColor: '#11457E',
      backgroundColor: '#11457E',
    });
  }
  if (dataSet4) {
    datasetTab.push({
      label: dataSet4Title,
      data: dataSet4,
      borderColor: '#c6ebfd',
      backgroundColor: '#c6ebfd',
    });
  }
  if (dataSet5) {
    datasetTab.push({
      label: dataSet5Title,
      data: dataSet5,
      borderColor: '#028a0f',
      backgroundColor: '#028a0f',
    });
  }
  if (dataSet6) {
    datasetTab.push({
      label: dataSet6Title,
      data: dataSet6,
      borderColor: '#3ded97',
      backgroundColor: '#3ded97',
    });
  }
  if (dataSet7) {
    datasetTab.push({
      label: dataSet7Title,
      data: dataSet7,
      borderColor: '#44444c',
      backgroundColor: '#44444c',
    });
  }
  if (dataSet8) {
    datasetTab.push({
      label: dataSet8Title,
      data: dataSet8,
      borderColor: '#d0a1a6',
      backgroundColor: '#d0a1a6',
    });
  }

  const config = {
    labels: labels,
    datasets: datasetTab
  };

  return <Line options={options} data={config} />;
};

const ReportCharts = ({ data, closeFnc }) => {
  const { t } = useTranslation();
  const [dataContext] = useContext(Context);
  const [dataPl, setDataPl] = useState([]);
  const [dataCz, setDataCz] = useState([]);
  const [dataSk, setDataSk] = useState([]);
  const [dataEast, setDataEast] = useState([]);
  const [period, setPeriod] = useState('');
  const [labels, setLabels] = useState([]);
  const [chxPl, setChxPl] = useState(false);
  const [chxPlVisible, setChxPlVisible] = useState(false);
  const [chxCz, setChxCz] = useState(false);
  const [chxCzVisible, setChxCzVisible] = useState(false);
  const [chxSk, setChxSk] = useState(false);
  const [chxSkVisible, setChxSkVisible] = useState(false);
  const [chxEast, setChxEast] = useState(false);
  const [chxEastVisible, setChxEastVisible] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setDataPl(data.filter(d => d.country === 'PL'));
      setDataCz(data.filter(d => d.country === 'CZ'));
      setDataSk(data.filter(d => d.country === 'SK'));
      setDataEast(data.filter(d => d.country === 'East'));
      setPeriod(dataContext.filters.period === '1' ?  t('monthly') : t('weekly'));
      setLabels(data[0].periods.map(period => period.periodName));

      if (data.filter(d => d.country === 'PL').length > 0) {
        setChxPl(true);
        setChxPlVisible(true);
      } else {
        setChxPl(false);
        setChxPlVisible(false);
      }

      if (data.filter(d => d.country === 'CZ').length > 0) {
        setChxCz(true);
        setChxCzVisible(true);
      } else {
        setChxCz(false);
        setChxCzVisible(false);
      }

      if (data.filter(d => d.country === 'SK').length > 0) {
        setChxSk(true);
        setChxSkVisible(true);
      } else {
        setChxSk(false);
        setChxSkVisible(false);
      }

      if (data.filter(d => d.country === 'East').length > 0) {
        setChxEast(true);
        setChxEastVisible(true);
      } else {
        setChxEast(false);
        setChxEastVisible(false);
      }
    }
  }, [data]);

  const countryChxHandler = (e) => {
    if (e.currentTarget.name === 'chxPl') {
      if (e.currentTarget.checked === false) {
        setChxPl(false);
        setDataPl([]);
      } else {
        setChxPl(true);
        setDataPl(data.filter(d => d.country === 'PL'));
      }
    }

    if (e.currentTarget.name === 'chxCz') {
      if (e.currentTarget.checked === false) {
        setChxCz(false);
        setDataCz([]);
      } else {
        setChxCz(true);
        setDataCz(data.filter(d => d.country === 'CZ'));
      }
    }

    if (e.currentTarget.name === 'chxSk') {
      if (e.currentTarget.checked === false) {
        setChxSk(false);
        setDataSk([]);
      } else {
        setChxSk(true);
        setDataSk(data.filter(d => d.country === 'SK'));
      }
    }

    if (e.currentTarget.name === 'chxEast') {
      if (e.currentTarget.checked === false) {
        setChxEast(false);
        setDataEast([]);
      } else {
        setChxEast(true);
        setDataEast(data.filter(d => d.country === 'East'));
      }
    }
  };

  const hideHandler = () => {
    closeFnc();
  };

  return (
      <Container>
        <Close onClick={hideHandler} />
        <PageTitle title={t('charts')} />
        <div>
          {chxPlVisible &&
            <FormControlLabel control={
              <Checkbox name='chxPl' checked={chxPl} onChange={countryChxHandler} />
            } label="PL" />
          }

          {chxCzVisible &&
            <FormControlLabel control={
              <Checkbox name='chxCz' checked={chxCz} onChange={countryChxHandler}/>
            } label="CZ"/>
          }

          {chxSkVisible &&
            <FormControlLabel control={
              <Checkbox name='chxSk' checked={chxSk} onChange={countryChxHandler} />
            } label="SK" />
          }

          {chxEastVisible &&
            <FormControlLabel control={
              <Checkbox name='chxEast' checked={chxEast} onChange={countryChxHandler} />
            } label="East" />
          }
        </div>
        <div>
          <Subtitle>Visits</Subtitle>
          <ChartLineConfig
              labels={labels}
              dataSet1={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.visits) : false}
              dataSet1Title='Visits Y PL'
              dataSet2={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.visitsM1Y) : false}
              dataSet2Title='Visits Y-1 PL'
              dataSet3={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.visits) : false}
              dataSet3Title='Visits Y CZ'
              dataSet4={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.visitsM1Y) : false}
              dataSet4Title='Visits Y-1 CZ'
              dataSet5={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.visits) : false}
              dataSet5Title='Visits Y SK'
              dataSet6={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.visitsM1Y) : false}
              dataSet6Title='Visits Y-1 SK'
              dataSet7={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.visits) : false}
              dataSet7Title='Visits Y East'
              dataSet8={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.visitsM1Y) : false}
              dataSet8Title='Visits Y-1 East'
              period={period}
          />
        </div>
        <div>
          <Subtitle>Average online cart</Subtitle>
          <ChartLineConfig
              labels={labels}
              dataSet1={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.turnoverPerVisit) : false}
              dataSet1Title='Average online cart PL'
              dataSet2={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.turnoverM1YPerVisit) : false}
              dataSet2Title='Average online cart Y-1 PL'
              dataSet3={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.turnoverPerVisit) : false}
              dataSet3Title='Average online cart Y CZ'
              dataSet4={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.turnoverM1YPerVisit) : false}
              dataSet4Title='Average online cart Y-1 CZ'
              dataSet5={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.turnoverPerVisit) : false}
              dataSet5Title='Average online cart Y SK'
              dataSet6={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.turnoverM1YPerVisit) : false}
              dataSet6Title='Average online cart Y-1 SK'
              dataSet7={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.turnoverPerVisit) : false}
              dataSet7Title='Average online cart Y East'
              dataSet8={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.turnoverM1YPerVisit) : false}
              dataSet8Title='Average online cart Y-1 East'
              period={period}
          />
        </div>
        <div>
          <Subtitle>Average online cart (EUR)</Subtitle>
          <ChartLineConfig
              labels={labels}
              dataSet1={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.turnoverEurPerVisit) : false}
              dataSet1Title='Average online cart (EUR) Y PL'
              dataSet2={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.turnoverEurM1YPerVisit) : false}
              dataSet2Title='Average online cart (EUR) Y-1 PL'
              dataSet3={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.turnoverEurPerVisit) : false}
              dataSet3Title='Average online cart (EUR) Y CZ'
              dataSet4={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.turnoverEurM1YPerVisit) : false}
              dataSet4Title='Average online cart (EUR) Y-1 CZ'
              dataSet5={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.turnoverEurPerVisit) : false}
              dataSet5Title='Average online cart (EUR) Y SK'
              dataSet6={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.turnoverEurM1YPerVisit) : false}
              dataSet6Title='Average online cart (EUR) Y-1 SK'
              dataSet7={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.turnoverEurPerVisit) : false}
              dataSet7Title='Average online cart (EUR) Y East'
              dataSet8={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.turnoverEurM1YPerVisit) : false}
              dataSet8Title='Average online cart (EUR) Y-1 East'
              period={period}
          />
        </div>
        <div>
          <Subtitle>Av basket</Subtitle>
          <ChartLineConfig
              labels={labels}
              dataSet1={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.turnoverPerVisit) : false}
              dataSet1Title='Av basket Y PL'
              dataSet2={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.turnoverM1YPerVisit) : false}
              dataSet2Title='Av basket Y-1 PL'
              dataSet3={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.turnoverPerVisit) : false}
              dataSet3Title='Av basket Y CZ'
              dataSet4={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.turnoverM1YPerVisit) : false}
              dataSet4Title='Av basket Y-1 CZ'
              dataSet5={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.turnoverPerVisit) : false}
              dataSet5Title='Av basket Y SK'
              dataSet6={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.turnoverM1YPerVisit) : false}
              dataSet6Title='Av basket Y-1 SK'
              dataSet7={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.turnoverPerVisit) : false}
              dataSet7Title='Av basket Y East'
              dataSet8={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.turnoverM1YPerVisit) : false}
              dataSet8Title='Av basket Y-1 East'
              period={period}
          />
        </div>
        <div>
          <Subtitle>Av basket (EUR)</Subtitle>
          <ChartLineConfig
              labels={labels}
              dataSet1={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.turnoverEurPerVisit) : false}
              dataSet1Title='Av basket Y PL'
              dataSet2={(dataPl && dataPl.length > 0) ? dataPl[0].periods.map(period => period.turnoverEurM1YPerVisit) : false}
              dataSet2Title='Av basket Y-1 PL'
              dataSet3={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.turnoverEurPerVisit) : false}
              dataSet3Title='Av basket Y CZ'
              dataSet4={(dataCz && dataCz.length > 0) ? dataCz[0].periods.map(period => period.turnoverEurM1YPerVisit) : false}
              dataSet4Title='Av basket Y-1 CZ'
              dataSet5={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.turnoverEurPerVisit) : false}
              dataSet5Title='Av basket Y SK'
              dataSet6={(dataSk && dataSk.length > 0) ? dataSk[0].periods.map(period => period.turnoverEurM1YPerVisit) : false}
              dataSet6Title='Av basket Y-1 SK'
              dataSet7={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.turnoverEurPerVisit) : false}
              dataSet7Title='Av basket Y East'
              dataSet8={(dataEast && dataEast.length > 0) ? dataEast[0].periods.map(period => period.turnoverEurM1YPerVisit) : false}
              dataSet8Title='Av basket Y-1 East'
              period={period}
          />
        </div>
      </Container>
  );
};

export default ReportCharts;
