import * as React from 'react';

function SvgChartPie(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.192 12l2.877 5.455C17.13 16.273 18 14.365 18 12c0-2.39-.87-4.273-2.955-5.407L12.192 12zm-1.055 0l3.452-6.614C17.418 6.352 19 8.717 19 12s-1.582 5.648-4.41 6.614L11.136 12zm2.421 6.855A8.313 8.313 0 0112 19c-4.267 0-7-2.752-7-7 0-4.297 2.733-7 7-7 .527 0 1.079.048 1.606.145l-.503.893C12.767 5.99 12.432 6 12 6c-3.715 0-6 2.258-6 6 0 3.693 2.308 6 6 6 .432 0 .744.01 1.079-.038l.48.893z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}

export default SvgChartPie;
