import * as React from 'react';

function SvgVisibilityShow(props) {
  return (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12 7.5c2.833 0 5.5 1.383 8 4.15v.8c-2.5 2.708-5.167 4.06-8 4.056-2.833-.004-5.5-1.356-8-4.056v-.8c2.498-2.764 5.162-4.147 7.992-4.15H12zm0 1a3.5 3.5 0 10.192.005L12 8.5zm3.704.946l.041.059c.477.714.755 1.572.755 2.495 0 .97-.307 1.87-.83 2.605 1.037-.525 2.056-1.282 3.058-2.276l.272-.277v-.011c-1.079-1.146-2.176-2.008-3.296-2.595zm-7.41 0l-.01.006c-1.026.54-2.033 1.31-3.024 2.319L5 12.04v.012c1.088 1.125 2.195 1.971 3.325 2.544A4.473 4.473 0 017.5 12c0-.949.293-1.829.795-2.554z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}

export default SvgVisibilityShow;
