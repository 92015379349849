import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const SliderComponent = ({onChangeFnc}) => {
  return (
        <Slider
            min={0}
            max={2}
            defaultValue={2}
            marks={{ 0: 'Area', 1: 'Dealer group', 2: 'Dealer' }}
            onChange={onChangeFnc}
        />
  )
};

export default SliderComponent;
