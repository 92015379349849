import * as React from 'react';

function Close(props) {
  return (
      <svg width={14} height={14} xmlns='http://www.w3.org/2000/svg' {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.25044 7L0 0.749558L0.749558 0L7 6.25044L13.2504 0L14 0.749558L7.74956 7L14 13.2504L13.2504 14L7 7.74956L0.749558 14L0 13.2504L6.25044 7Z" />
      </svg>
  );
}

export default Close;
