import React from 'react';
import { ThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const muiTheme = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        border: '2px solid black',
      }
    },
    MuiInputLabel: {
      root: {
        color: "#e91630",
      },
    },
  },
});

const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    gray: '#ccc',
    grayDisabled: '#888b8d',
    bgLightGrayColor: '#F3F5F6',
    dBrandColor: '#646b52',
    rBrandColor: '#efdf00',
    errorColor: '#e91630',
    textColor: '#333',
    fcVisits: '#ec6528',
    fcTurnover: '#72c293',
    fcBasket: '#0091da',
    fcDefault: '#d9d9d6',
  },
  sizes: {
    contentWidth: '1280px'
  }
};

const Theme = ({ children }) => (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
);

export default Theme;
