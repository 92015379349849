import React from 'react';
import styledSC from 'styled-components';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';

const Title = styledSC.div`
  position: relative;
  display: inline-block;
  width: auto;
  margin-right: 30px;
  padding-left: 320px;
  transition: padding-left .2s ease-in-out;
  
  &.no-padding {
    padding-left: 0;
  }
  
  .hideSideBar & {
    padding-left: 50px;
  }
`;

const TitleTypography = styled(Typography)`
  font-family: 'NouvelRBold';
`;

const PageTitle = ({title, noPadding}) => {
  return (
      <Title className={noPadding ? 'no-padding' : ''}>
        <TitleTypography variant='h5'>{title}</TitleTypography>
      </Title>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
