import React, {useContext, useEffect, useState} from 'react';
import CustomScrollbars from '../_shared/CustomScrollbars';
import JwtInterceptor from '../_shared/JwtInterceptors';
import Loader from "../_shared/Loader";
import NoData from '../_shared/NoData';
import ReportTop20Table from './ReportTop20Table';
import { Context } from '../../commons/context';
import AuthContext from '../_shared/AuthContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Slider from '../_shared/Slider';
import styledSC from "styled-components";

const SliderContainer = styledSC.div`
  position: absolute;
  top: 10px; 
  right: 60px;
  z-index: 1;
  display: flex;
  white-space: nowrap;
`;

const API_URL = process.env.REACT_APP_API_URL;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box sx={{ p: 3 }}>
              {children}
            </Box>
        )}
      </div>
  );
};

const ReportTop20Data = ({ queryFilters, isLoading, setIsLoading, noData, setNoData }) => {
  const { logout } = useContext(AuthContext);
  const [dataContext, setDataContext] = useContext(Context);
  const [value, setValue] = useState(0);
  const [rData, setRData] = useState([]);
  const [error, setError] = useState(false);
  const [sliderVal, setSliderVal] = useState(2);
  const filtersBarHeight = document.getElementById('filtersBar') ? document.getElementById('filtersBar').offsetHeight : 0;
  const catBarheight = document.getElementById('catBar') ? document.getElementById('catBar').offsetHeight : 0;
  const scrollbarHeight = document.getElementById('filtersBar') ? window.innerHeight - (250 + filtersBarHeight + catBarheight) : window.innerHeight - 170;

  useEffect(() => {
    const getData = async () => {
      let queryF = dataContext.filters.queryFilters;

      setIsLoading(true);
      setError(false);

      if (!queryF || queryF === '') {
        const now = new Date();
        const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const endDate = new Date(now.setDate(0));
        const startDateQuery = `${startDate.getFullYear()}-${startDate.getMonth()+1}-01`;
        const endDateQuery = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;

        queryF = `StartDate=${startDateQuery}&EndDate=${endDateQuery}&Period=week`;
      }

      if (queryF.indexOf('Categories') < 0) {
        if (dataContext.filters.categories.length > 0) {
          const qCategories = dataContext.filters.categories.map(c => `&Categories=${c}`);
          queryF += qCategories.join('');
        } else {
          setIsLoading(false);
          setNoData(true);
          return;
        }
      }

      JwtInterceptor
          .get(`${API_URL}Data/CategoriesReport?${queryF}`)
          .then((result) => {
            setIsLoading(false);

            if (result.data.length > 0) {
              setNoData(false);
            } else {
              setNoData(true);
            }

            setRData(result.data);
          }).catch(error => {
            console.log('Country Error', error);
            setIsLoading(false);
            setNoData(true);
            setError(true);
            if (error.response.status === 400) logout();
      });
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.filters.queryFilters]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sliderHandler = val => {
    setSliderVal(val);
  };

  const TabsHeader = rData.map((d, i) => <Tab label={d.country} key={`tab_${i}`} />);
  const TabsContent = rData.map((d, i) => {
    return(
        <TabPanel value={value} index={i} key={`tc_${i}`}>
          <CustomScrollbars style={{width: 'auto', height: scrollbarHeight}}>
            <ReportTop20Table tableData={d} sliderVal={sliderVal} />
          </CustomScrollbars>
        </TabPanel>
    )
  });

  return (
      <div className='page-wrapper'>
        {isLoading ? <Loader /> : (
            <div>
              {noData ? <NoData error={error} categories={true} /> : (
                <Box sx={{ width: '100vw', height: '100vh' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <SliderContainer>
                      <Slider onChangeFnc={sliderHandler} />
                    </SliderContainer>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      {TabsHeader}
                    </Tabs>
                  </Box>
                  {TabsContent}
                </Box>
              )}
            </div>
        )}
      </div>
  );
};

export default ReportTop20Data;
