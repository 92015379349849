import React, {useEffect, useState} from 'react';
import { Controller, useForm } from "react-hook-form";
import styledSC from 'styled-components';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import JwtInterceptor from '../_shared/JwtInterceptors';
import Autocomplete from '@mui/material/Autocomplete';
import CustomButton from "../_shared/CustomButton";
import Loader from "../_icons/Loader";
import MuiAlert from '@mui/material/Alert';
import PageTitle from '../_shared/PageTitle';
import Snackbar from '@mui/material/Snackbar';
import TextField from "@mui/material/TextField/TextField";
import StylesTextField from '../../commons/_muiOverride/StylesTextField';

const API_URL = process.env.REACT_APP_API_URL;

const Container = styledSC.div`
  width: 300px;
  padding: 20px;
`;

const Form = styledSC.form`
  width: 300px;
  margin-top: 25px;
  text-align: left;
`;

const Label = styledSC.label`
  display: block;
`;

const ErrorSpan = styledSC.span`
  display: block;
  margin-top: -10px;
  font-size: 12px;
  color: ${props => props.theme.colors.errorColor};
`;

const Input = styled(TextField)`
  ${StylesTextField}
`;

const Alert = React.forwardRef(function Alert(props, ref,) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertError = React.forwardRef(function Alert(props, ref,) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CategoriesAddForm = () => {
  const { t } = useTranslation();
  const { control, register, handleSubmit, reset, formState: { errors } } = useForm();
  const [itemCodesData, setItemCodesData] = useState([]);
  const [itemFamiliesData, setItemFamiliesData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [sendErrorText, setSendErrorText] = useState(t('errGeneral'));

  useEffect(() => {
    const getItemCodesData = async () => {
      JwtInterceptor
          .get(`${API_URL}Categories/itemCodes`)
          .then((result) => {
            setItemCodesData(result.data);
          })
          .catch(function(error) {
            console.log(error);
            // logout();
          });
    };

    const getItemFamiliesData = async () => {
      JwtInterceptor
          .get(`${API_URL}Categories/itemFamilies`)
          .then((result) => {
            setItemFamiliesData(result.data);
          })
          .catch(function(error) {
            console.log(error);
            // logout();
          });
    };

    getItemCodesData();
    getItemFamiliesData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onSubmit = async (data) => {
    JwtInterceptor
        .post(`${API_URL}Categories`, data)
        .then(() => {
          setSendSuccess(true);
          reset();
          setLoader(false);
        })
        .catch(function(error) {
          console.log(error);
          if (error.response.data.status === 400) {
            setSendErrorText(error.response.data.detail);
          }
          setLoader(false);
          // logout();
        });
  };

  const handleCloseSuccess = () => {
    setSendSuccess(false);
  };

  const handleCloseError = () => {
    setSendError(false);
  };

  return (
      <Container>
        <PageTitle title={t('addCategoryFormTitle')} noPadding={true} />
        {sendSuccess &&
          <Snackbar open={sendSuccess} autoHideDuration={6000} onClose={handleCloseSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity="success" sx={{ width: '100%' }}>{t('addCategorySuccess')}</Alert>
          </Snackbar>
        }
        {sendError &&
          <Snackbar open={sendError} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <AlertError severity="error" sx={{ width: '100%' }}>{sendErrorText}</AlertError>
          </Snackbar>
        }
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Label>
            <Input id="catName" label={t('addCategoryFormFieldName')} variant="standard" {...register('name', { required: true })} maxLength='50' />
            {errors.name && <ErrorSpan>{t('errFieldRequired')}</ErrorSpan>}
          </Label>
          <Label>
            <Controller
                control={control}
                name="itemFamilies"
                defaultValue={itemFamiliesData}
                render={({ field: { ref, onChange, value, ...field } }) => (
                    <Autocomplete
                        multiple
                        options={itemFamiliesData}
                        defaultValue={itemFamiliesData}
                        value={value}
                        getOptionLabel={(option) => option}
                        onChange={(_, data) => onChange(data)}
                        renderInput={(params) => (
                            <Input
                                {...field}
                                {...params}
                                fullWidth
                                inputRef={ref}
                                label={t('addCategoryFormFieldItemFamilies')}
                                variant="standard"
                            />
                        )}
                    />
                )}
            />
          </Label>
          <Label>
            <Controller
                control={control}
                name="itemCodes"
                defaultValue={itemCodesData}
                render={({ field: { ref, onChange, value, ...field } }) => (
                    <Autocomplete
                        multiple
                        options={itemCodesData}
                        defaultValue={itemCodesData}
                        value={value}
                        getOptionLabel={(option) => option}
                        onChange={(_, data) => onChange(data)}
                        renderInput={(params) => (
                            <Input
                                {...field}
                                {...params}
                                fullWidth
                                inputRef={ref}
                                label={t('addCategoryFormFieldItemCodes')}
                                variant="standard"
                            />
                        )}
                    />
                )}
            />
          </Label>
          <CustomButton type="submit" text={t('addCategory')} icon={loader ? <Loader/> : ''} />
        </Form>
      </Container>
  );
};

export default CategoriesAddForm;
