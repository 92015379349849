export const DefaultData = {
  filters: {
    startDate: '',
    endDate: '',
    period: '',
    categories: [],
    categoriesData: {},
    cumulative: true,
    countries: [],
    areas: [],
    groups: [],
    dealers: [],
    customerTypes: [],
    invoiceTypes: [1],
    paymentTypes: [],
    vehicleAge: [],
    vehicleAgeType: false,
    vehicleAgeAll: false,
    vehicleBrands: [],
    vehicleSegments: [],
    vehicleModels: [],
    workshopTypes: [],
    queryFilters: '',
    changeCat: false
  }
};

export const DataReducer = (data, { type, payload }) => {
  switch (type) {
    case 'SET_FILTERS':
      return {
        ...data,
        filters: {
          ...payload,
        }
      };
  default:
    return data;
  }
};
