import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import DownloadReport from '../_shared/DownloadReport';
import ReportInsuranceData from './ReportInsuranceData';
import ReportFiltersBar from './ReportFiltersBar';
import SideBar from '../_shared/SideBar';
import TitleContainer from '../_shared/TitleContainer';

const ReportInsurance = () => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState([]);
  const [queryFilters, setQueryFilters] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(true);

  return (
      <div className='page-wrapper'>
        <SideBar setQueryFilters={setQueryFilters} isLoading={isLoading} filtersData={filtersData} setFiltersData={setFiltersData} cumulativeField={true} />
        <TitleContainer text={t('reportInsurance')}>
          <DownloadReport disabled={noData} reportType={'Insurance'} />
        </TitleContainer>
        <ReportFiltersBar filtersData={filtersData} queryFilters={queryFilters} noTimePeriod={false} noInTotal={false} />
        <ReportInsuranceData isLoading={isLoading} setIsLoading={setIsLoading} noData={noData} setNoData={setNoData} />
      </div>
  );
}

export default ReportInsurance;