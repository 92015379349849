import React, { useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import styledSC from 'styled-components';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ArrowRight from '../_icons/ArrowRight';
import AuthContext from '../_shared/AuthContext';
import CustomButton from '../_shared/CustomButton';
import MuiAlert from '@mui/material/Alert';
import Loader from '../_icons/Loader';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import VisibilityHide from '../_icons/VisibilityHide';
import VisibilityShow from '../_icons/VisibilityShow';

const FormContainer = styledSC.div`
  flex-grow: 0;
  flex-basis: 50%;
  width: 50%;
  margin: 0;
  box-shadow: 0px -2px 15px 5px ${props => props.theme.colors.gray};
`;

const FormWrapper = styledSC.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
`;

const FormTitle = styledSC.h1`
  font-size: 36px;
  line-height: 45px;
  margin: 0;
  color: ${props => props.theme.colors.textColor};
`;

const Form = styledSC.form`
  width: 300px;
  margin-top: 25px;
`;

const Label = styledSC.label`
  display: block;
  
  &.password {
    position: relative;
  }
  
  .icon {
    position: absolute;
    top: 35px;
    right: 0;
    
    &:hover {
      cursor: pointer;
    }
  }
`;

const ErrorSpan = styledSC.span`
  display: block;
  margin-top: -10px;
  font-size: 12px;
  color: ${props => props.theme.colors.errorColor};
`;

const Input = styled(TextField)`
  width: 100%;
  margin: 15px 0;
  color: #333;
  font-family: 'NouvelRRegular';
  
  & label {
    font-family: 'NouvelRRegular';
    color: #333;
    
    &.Mui-focused {
      color: #333;
    }
  }
  
  & input {
    font-family: 'NouvelRRegular';
    color: #333;
  }
  
  & .MuiInput-underline:after {
    border-bottom: 1px solid #333;
  }
  
  & .MuiInput-underline:before {
    border-bottom: 1px solid #333 !important;
  }
`;

const Alert = React.forwardRef(function Alert(props, ref,) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginForm = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { login, loginLoader, loginError } = useContext(AuthContext);
  const [passFieldType, setPassFieldType] = useState('password');
  const loginErrorTxt = t('errInvalidLoginData');

  const onSubmit = async (data) => {
    if (loginLoader) return;

    let payload = {
      userName: data.userName,
      password: data.password
    };

    await login(payload);
  };

  const handleClose = () => {
    // setLoginError(false);
  };

  const changeType = () => {
    if (passFieldType === 'password') {
      setPassFieldType('text');
    } else {
      setPassFieldType('password');
    }
  };

  return (
      <FormContainer>
        <FormWrapper>
          <FormTitle>{t('loginPageTitle')}</FormTitle>
            <Snackbar open={loginError} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert severity="error" sx={{ width: '100%' }}>{loginErrorTxt}</Alert>
            </Snackbar>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Label>
              <Input id="login" label={t('login')} variant="standard" {...register('userName', { required: true })} maxLength='50' />
              {errors.userName && <ErrorSpan>{t('errFieldRequired')}</ErrorSpan>}
            </Label>
            <Label className="password">
              <Input id="password" label={t('password')} type={passFieldType} variant="standard" {...register('password', { required: true })} maxLength='50' />
              {passFieldType === 'password' ? <VisibilityShow className="icon" onClick={changeType} /> : <VisibilityHide className="icon" onClick={changeType} />}
              {errors.password && <ErrorSpan>{t('errFieldRequired')}</ErrorSpan>}
            </Label>
            <CustomButton type="submit" text={t('loginBtnText')} icon={loginLoader ? <Loader/> : <ArrowRight />} />
          </Form>
        </FormWrapper>
      </FormContainer>
  );
};

export default LoginForm;
