import React, {useContext, useEffect, useState} from 'react';
import styledSC from 'styled-components';
import JwtInterceptor from '../_shared/JwtInterceptors';
import CustomScrollbars from '../_shared/CustomScrollbars';
import DeleteIcon from '../_icons/Delete';
import Loader from "../_shared/Loader";
import AuthContext from '../_shared/AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

const Table = styledSC.table`
  max-width: 40%;
  margin-left: 320px;
  transition: margin-left .2s ease-in-out;
  
  .hideSideBar & {
    margin-left: 20px;
  }
  
  td {
    white-space: wrap;
  }
`;

const DeleteIconWrapper = styledSC.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
`;

const IconCell = styledSC.td`
  position  relative;
  min-width: 24px;
`;

const CategoriesData = ({ refresh, setRefreshFnc }) => {
  const { logout } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      JwtInterceptor
        .get(`${API_URL}Categories`)
        .then((result) => {
          setCategories(result.data);
          setRefreshFnc(false);
          setIsLoading(false)
        }).catch(error => {
          console.log('Category', error);
          setRefreshFnc(false);
          setIsLoading(false);
          if (error.response.status === 400) logout();
        });
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    const deleteCategory = async () => {
      if (deleteCategoryId) {
        JwtInterceptor
            .delete(`${API_URL}Categories/${deleteCategoryId}`)
            .then(() => {
              setDeleteCategoryId(null);
              setRefreshFnc(true);
            }).catch(error => {
          console.log('Categories delete Category', error);
        });
      }
    };

    deleteCategory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCategoryId]);

  const deleteCategoryHandler = (id) => {
    setDeleteCategoryId(id);
  };

  const tabRows = categories.map(category => {
    return (
        <tr key={category.id}>
          <td>{category.name}</td>
          <td>{category.itemFamilies.join(' | ')}</td>
          <td>{category.itemCodes.join(' | ')}</td>
          <IconCell><DeleteIconWrapper onClick={() => deleteCategoryHandler(category.id)}><DeleteIcon/></DeleteIconWrapper></IconCell>
        </tr>
    );
  });

  return (
    <CustomScrollbars style={{ width: 'auto', height: 'calc(100% - 150px)' }}>
      {isLoading ? <Loader /> : (
        <Table>
          <thead>
            <tr>
              <th>Category name</th>
              <th>Item Families</th>
              <th>Item Codes</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tabRows}
          </tbody>
        </Table>
      )}
    </CustomScrollbars>
  );
};

export default CategoriesData;
