import React from 'react';
import styledSC from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

const Container = styledSC.div`
  margin-top: 20px;
  
  &:first-child {
    margin-top: 0;
  }
`;

const TableContainer = styledSC.div`
  display: flex;
`;

const Title = styledSC.div`
  margin: 10px 0;
  font-size: 24px;
  font-family: 'NouvelRBold';
`;

const valueColor = val => {
  if (val > 0) {
    return 'f-to-right f-plus';
  } else if (val < 0) {
    return 'f-to-right f-minus';
  } else {
    return 'f-to-right';
  }
};

const ReportVisitsKpiTableRowR = ({ data }) => {
  const rows = data.periods.map((row, i) => {
    return (
      <React.Fragment key={i}>
        <td className="f-to-right"><NumericFormat value={row.visitsRenault} thousandSeparator=" " displayType="text"/></td>
        <td className="f-to-right"><NumericFormat value={row.kpiRenault} thousandSeparator=" " displayType="text"/></td>
        <td className={`${valueColor(row.kpiRenaultPercentage)} t-right-border f-to-right`}><NumericFormat value={row.kpiRenaultPercentage} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
      </React.Fragment>
    );
  });

  return rows;
};

const ReportVisitsKpiTableRowD = ({ data }) => {
  const rows = data.periods.map((row, i) => {
    return (
        <React.Fragment key={i}>
          <td className="f-to-right"><NumericFormat value={row.visitsDacia} thousandSeparator=" " displayType="text"/></td>
          <td className="f-to-right"><NumericFormat value={row.kpiDacia} thousandSeparator=" " displayType="text"/></td>
          <td className={`${valueColor(row.kpiDaciaPercentage)} t-right-border f-to-right`}><NumericFormat value={row.kpiDaciaPercentage} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
        </React.Fragment>
    );
  });

  return rows;
};

const ReportVisitsKpiTable = ({ tableData, title, showPeriodName = false, periodSelect }) => {
  const { t } = useTranslation();
  const periodsLength = tableData.vehicleAges[0].periods.length;
  const columnsTitle = [];
  const months = t('monthsTab', { returnObjects: true });
  let periodsTitle, r0y, r13y, r46y, r710y, r010y, r110y, d0y, d13y, d46y, d710y, d010y, d110y;
  let r0ykpi, r13ykpi, r46ykpi, r710ykpi, r010ykpi, r110ykpi, d0ykpi, d13ykpi, d46ykpi, d710ykpi, d010ykpi, d110ykpi = 0;

  const rowsR = tableData.vehicleAges.map((row, i) => {
    if (row.vehicleAge === '0Y') {
      r0y = <ReportVisitsKpiTableRowR data={row}/>;
      r0ykpi = row.periods[0].kpiRenault;
    }
    if (row.vehicleAge === '1-3Y') {
      r13y = <ReportVisitsKpiTableRowR data={row} />;
      r13ykpi = row.periods[0].kpiRenault;
    }
    if (row.vehicleAge === '4-6Y') {
      r46y = <ReportVisitsKpiTableRowR data={row} />;
      r46ykpi = row.periods[0].kpiRenault;
    }
    if (row.vehicleAge === '7-10Y') {
      r710y = <ReportVisitsKpiTableRowR data={row} />;
      r710ykpi = row.periods[0].kpiRenault;
    }
    if (row.vehicleAge === '0-10Y') {
      r010y = <ReportVisitsKpiTableRowR data={row} />;
      r010ykpi = row.periods[0].kpiRenault;
    }
    if (row.vehicleAge === '1-10Y') {
      r110y = <ReportVisitsKpiTableRowR data={row} />;
      r110ykpi = row.periods[0].kpiRenault;
    }
  });

  const rowsD = tableData.vehicleAges.map((row, i) => {
    if (row.vehicleAge === '0Y') {
      d0y = <ReportVisitsKpiTableRowD data={row} />;
      d0ykpi = row.periods[0].kpiDacia;
    }
    if (row.vehicleAge === '1-3Y') {
      d13y = <ReportVisitsKpiTableRowD data={row} />;
      d13ykpi = row.periods[0].kpiDacia;
    }
    if (row.vehicleAge === '4-6Y') {
      d46y = <ReportVisitsKpiTableRowD data={row} />;
      d46ykpi = row.periods[0].kpiDacia;
    }
    if (row.vehicleAge === '7-10Y') {
      d710y = <ReportVisitsKpiTableRowD data={row} />;
      d710ykpi = row.periods[0].kpiDacia;
    }
    if (row.vehicleAge === '0-10Y') {
      d010y = <ReportVisitsKpiTableRowD data={row} />;
      d010ykpi = row.periods[0].kpiDacia;
    }
    if (row.vehicleAge === '1-10Y') {
      d110y = <ReportVisitsKpiTableRowD data={row} />;
      d110ykpi = row.periods[0].kpiDacia;
    }
  });

  if (showPeriodName) {
    const periodNames = tableData.vehicleAges[0].periods.map(p => (periodSelect === "1") ? `${months[p.period - 1]} / ${p.year}` : `${p.period} / ${p.year}`);
    periodsTitle = periodNames.map(p => <th colSpan="3">{p}</th>);
  }

  for (let i = 0; i <= periodsLength-1; i++) {
    columnsTitle.push(<React.Fragment key={`th_${i}`}><th className="bg--gray">{t('visits')}</th><th className="bg--gray">KPI</th><th className="bg--gray">%</th></React.Fragment>);
  }

  return (
      <Container>
        <Title>{title}</Title>
        <TableContainer>
          <table>
            <thead>
              {showPeriodName && <tr><td className="f-transparent bg-white">a</td></tr>}
              <tr><td className="f-transparent bg-white">a</td></tr>
              <tr><td className={showPeriodName ? "t-bottom-border f-transparent bg--gray" : "t-bottom-border f-transparent bg-white"}>a</td></tr>
            </thead>
            <tbody>
             {r0ykpi > 0 && d0ykpi > 0 && <tr><td className="t-right-border t-left-border">0Y</td></tr>}
             {r13ykpi > 0 && d13ykpi > 0 && <tr><td className="t-right-border t-left-border">1-3Y</td></tr>}
             {r46ykpi > 0 && d46ykpi > 0 && <tr><td className="t-right-border t-left-border">4-6Y</td></tr>}
             {r710ykpi > 0 && d710ykpi > 0 && <tr><td className="t-right-border t-left-border">7-10Y</td></tr>}
             {r010ykpi > 0 && d010ykpi > 0 && <tr><td className="t-right-border t-left-border">0-10Y</td></tr>}
             {r110ykpi > 0 && d110ykpi > 0 && <tr><td className="t-right-border t-left-border">1-10Y</td></tr>}
            </tbody>
          </table>
          <table>
            <thead>
            <tr>
              <th colSpan={periodsLength * 3} className="bg-renault">Renault</th>
            </tr>
            {showPeriodName &&
              <tr>
                {periodsTitle}
              </tr>
            }
            <tr>
              {columnsTitle}
            </tr>
            </thead>
            <tbody>
              {r0ykpi > 0 && d0ykpi > 0 && <tr>{r0y}</tr>}
              {r13ykpi > 0 && d13ykpi > 0 && <tr>{r13y}</tr>}
              {r46ykpi > 0 && d46ykpi > 0 && <tr>{r46y}</tr>}
              {r710ykpi > 0 && d710ykpi > 0 && <tr>{r710y}</tr>}
              {r010ykpi > 0 && d010ykpi > 0 && <tr>{r010y}</tr>}
              {r110ykpi > 0 && d110ykpi > 0 && <tr>{r110y}</tr>}
            </tbody>
          </table>
          <table>
            <thead>
            <tr>
              <th colSpan={periodsLength * 3} className="bg-dacia">Dacia</th>
            </tr>
            {showPeriodName &&
              <tr>
                {periodsTitle}
              </tr>
            }
            <tr>
              {columnsTitle}
            </tr>
            </thead>
            <tbody>
              {r0ykpi > 0 && d0ykpi > 0 && <tr>{d0y}</tr>}
              {r13ykpi > 0 && d13ykpi > 0 && <tr>{d13y}</tr>}
              {r46ykpi > 0 && d46ykpi > 0 && <tr>{d46y}</tr>}
              {r710ykpi > 0 && d710ykpi > 0 && <tr>{d710y}</tr>}
              {r010ykpi > 0 && d010ykpi > 0 && <tr>{d010y}</tr>}
              {r110ykpi > 0 && d110ykpi > 0 && <tr>{d110y}</tr>}
            </tbody>
          </table>
        </TableContainer>
      </Container>
  );
};

export default ReportVisitsKpiTable;
