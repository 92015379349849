import React from 'react';
import styledSC from 'styled-components';
import { useTranslation } from 'react-i18next';
import NoDataIcon from '../_icons/NoData';

const Wrapper = styledSC.div`
  position: absolute;
  top: 0;
  left: 150px;
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left .2s ease-in-out;
  
  .hideSideBar & {
    left: 0;
  }
`;

const NoDataContent = styledSC.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .paragraph {
    margin-top: 16px;
    font-size: 20px;
    line-height: 24px;
    
    &--first {
      margin-top: 40px;
      text-transform: uppercase;
    }
  }
`;

function NoData({error, categories = false}) {
  const { t } = useTranslation();

  return (
      <Wrapper>
        <NoDataContent>
          <NoDataIcon/>
          {!error &&
            <>
              <p className="paragraph paragraph--first"><b>{t('noData')}</b></p>
              <p className="paragraph">{t('noData2')}</p>
              {categories &&
                <p className="paragraph">{t('categoriesError')}</p>
              }
            </>
          }
          {error &&
            <>
              <p className="paragraph paragraph--first"><b>{t('error')}</b></p>
              <p className="paragraph">{t('error2')}</p>
            </>
          }
        </NoDataContent>
      </Wrapper>
  );
}

export default NoData;
