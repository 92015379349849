import React, {useState} from 'react';
import PageTitle from "../_shared/PageTitle";
import CloseIcon from "../_icons/Close";
import styledSC from "styled-components";
import { Controller, useForm } from "react-hook-form";
import {styled} from "@mui/material";
import TextField from "@mui/material/TextField/TextField";
import StylesTextField from "../../commons/_muiOverride/StylesTextField";
import Loader from "../_icons/Loader";
import MuiAlert from "@mui/material/Alert/Alert";
import JwtInterceptor from "../_shared/JwtInterceptors";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { useTranslation } from 'react-i18next';
import CustomButton from "../_shared/CustomButton";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";

const API_URL = process.env.REACT_APP_API_URL;

const Container = styledSC.div`
  width: 500px;
  padding: 20px;
`;

const Close = styledSC(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 10px;
  padding: 10px;
  
  &:hover {
    cursor: pointer;
  }
`;

const Form = styledSC.form`
  width: 500px;
  margin-top: 25px;
  text-align: left;
`;

const Label = styledSC.label`
  display: block;
`;

const Input = styled(TextField)`
  ${StylesTextField}
`;

const Alert = React.forwardRef(function Alert(props, ref,) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertError = React.forwardRef(function Alert(props, ref,) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ImportCrm = ({ data, closeFnc, setRefreshTable }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [recalculate, setRecalculate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [sendErrorText, setSendErrorText] = useState(t('errGeneral'));

  const onSubmit = async (data) => {
    if (!loader) {
      setLoader(true);
      setSendError(false);

      JwtInterceptor
          .post(`${API_URL}Import/crm?recalculateCampaign=${recalculate}`, data, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(() => {
            setSendSuccess(true);
            setLoader(false);
            setRefreshTable(true);
            setRecalculate(false);
          })
          .catch(function (error) {
            console.log(error);
            setSendErrorText(t('error'));
            setSendError(true);
            setLoader(false);
          });
    }
  };

  const handleCloseSuccess = () => {
    setSendSuccess(false);
  };

  const handleCloseError = () => {
    setSendError(false);
  };

  const hideHandler = () => {
    closeFnc();
  };

  return (
      <Container>
        <Close onClick={hideHandler} />
        <PageTitle title="Import" noPadding={true} />
        {sendSuccess &&
          <Snackbar open={sendSuccess} autoHideDuration={6000} onClose={handleCloseSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity="success" sx={{ width: '100%' }}>{t('addCRMSuccess')}</Alert>
          </Snackbar>
        }
        {sendError &&
          <Snackbar open={sendError} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <AlertError severity="error" sx={{ width: '100%' }}>{sendErrorText}</AlertError>
          </Snackbar>
        }
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Label>
            <Controller
                control={control}
                name="file"
                rules={{ required: "Recipe picture is required" }}
                render={({ field: { value, onChange, ...field } }) => {
                  return (
                      <Input
                          {...field}
                          value={value?.fileName}
                          onChange={(event) => {
                            onChange(event.target.files[0]);
                          }}
                          type="file"
                          id="file"
                      />
                  );
                }}
            />
          </Label>

          <div>
            <FormControlLabel control={
              <Checkbox name='recalculateCampaign' checked={recalculate} onChange={(e) => setRecalculate(e.target.checked)} />
            } label={t('recalculateCampaign')} />
          </div>

          <CustomButton type="submit" text={t('importReport')} icon={loader ? <Loader/> : ''} />
        </Form>
      </Container>
  );
};

export default ImportCrm;
