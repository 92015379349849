import * as React from 'react';

function Logout(props) {
  return (
      <svg width={14} height={15} xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d="M6.85355 14.1464C6.94732 14.2402 7 14.3674 7 14.5C7 14.6326 6.94732 14.7598 6.85355 14.8536C6.75978 14.9473 6.63261 15 6.5 15H1C0.734783 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14V1C0 0.734783 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734783 0 1 0H6.5C6.63261 0 6.75978 0.0526781 6.85355 0.146446C6.94732 0.240214 7 0.367392 7 0.5C7 0.632608 6.94732 0.759785 6.85355 0.853554C6.75978 0.947322 6.63261 1 6.5 1H1V14H6.5C6.63261 14 6.75978 14.0527 6.85355 14.1464Z" />
        <path d="M13.5 7.50764C13.4998 7.63805 13.4485 7.76318 13.357 7.85614L11.3535 9.85964C11.2592 9.95072 11.1329 10.0011 11.0018 9.99998C10.8707 9.99884 10.7453 9.94626 10.6526 9.85355C10.5599 9.76085 10.5073 9.63544 10.5062 9.50434C10.505 9.37325 10.5554 9.24694 10.6465 9.15265L11.793 8.00614H5C4.86739 8.00614 4.74022 7.95347 4.64645 7.8597C4.55268 7.76593 4.5 7.63875 4.5 7.50614C4.5 7.37354 4.55268 7.24636 4.64645 7.15259C4.74022 7.05882 4.86739 7.00614 5 7.00614H11.793L10.6465 5.85964C10.5987 5.81352 10.5607 5.75835 10.5345 5.69735C10.5082 5.63634 10.4945 5.57073 10.4939 5.50434C10.4933 5.43795 10.5059 5.37212 10.5311 5.31067C10.5562 5.24922 10.5934 5.19339 10.6403 5.14645C10.6872 5.0995 10.7431 5.06237 10.8045 5.03723C10.866 5.01209 10.9318 4.99944 10.9982 5.00002C11.0646 5.0006 11.1302 5.01439 11.1912 5.04059C11.2522 5.0668 11.3074 5.10489 11.3535 5.15264L13.3535 7.15264C13.447 7.24593 13.4997 7.37253 13.5 7.50464V7.50764Z" />
      </svg>
  );
}

export default Logout;
