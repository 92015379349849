import React from 'react';
import { useTranslation } from 'react-i18next';
import styledSC from 'styled-components';
import PropTypes from "prop-types";
import PageTitle from '../_shared/PageTitle';

const Container = styledSC.div`
  display: flex;
  align-items: center;
  min-height: 60px;
`;

const ChildrenContainer = styledSC.div`
  position: absolute;
  right: 40px;
  display: flex;
  top: 12px;
`;

const TitleContainer = ({ text, children }) => {
  const { t } = useTranslation();

  return (
      <Container>
        <PageTitle title={text} />
        <ChildrenContainer>
          {children}
        </ChildrenContainer>
      </Container>
  );
};

TitleContainer.defaultProps = {
  text: '',
};

TitleContainer.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TitleContainer;
