import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import styledSC from "styled-components";
import { Context } from '../../commons/context';
import _ from 'lodash';

const FiltersWrapper = styledSC.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  padding-left: 320px;
  transition: padding-left .2s ease-in-out;
  
  .hideSideBar & {
    padding-left: 20px;
  }
`;

const FiltersTitle = styledSC.span`
  position: relative;
  top: 4px;
  margin-right: 10px;
  font-size: 16px;
`;

const Filter = styledSC.div`
  margin: 0 10px 5px 0;
  padding: 2px 8px 4px;
  font-size: 14px;
  line-height: 22px;
  background-color: ${props => props.theme.colors.bgLightGrayColor};
  border-radius: 8px;
`;

const ReportFiltersBar = ({ filtersData, queryFilters, onlyDate = false, noTimePeriod = false, noInTotal = false }) => {
  const { t } = useTranslation();
  const [dataContext] = useContext(Context);
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
  let startDateFormatted, endDateFormatted = '';
  let queryTab, startDate, endDate, period, cumulative, countries, areas, groups, dealers, customerTypes, invoices, paymentTypes, vehicleAges, vehicleBrands, vehicleSegments, vehicleModels, workshopTypes;

  if (dataContext.filters.queryFilters && !_.isEmpty(filtersData)) {
    queryTab = dataContext.filters.queryFilters.split('&').map(f => f.split('='));

    startDate = queryTab.filter(f => f[0] === 'StartDate')[0][1];
    endDate = queryTab.filter(f => f[0] === 'EndDate')[0][1];

    const newStartDate = new Date(startDate);
    startDateFormatted = `${months[newStartDate.getMonth()]} ${startDate.substr(0, 4)}`;

    const newEndDate = new Date(endDate);
    endDateFormatted = `${months[newEndDate.getMonth()]} ${endDate.substr(0, 4)}`;

    if (!onlyDate) {
      period = queryTab.filter(f => f[0] === 'Period')[0][1];
      cumulative = queryTab.filter(f=>f[0] === 'Cumulative').length > 0 ? queryTab.filter(f=>f[0] === 'Cumulative')[0][1] : 'true';
      countries = queryTab.filter(f => f[0] === 'Countries').map(f => f[1]).map(c => filtersData.geographical.countries.filter(f => Number(c) === f.id)).map(c => c[0].name).sort();
      areas = queryTab.filter(f => f[0] === 'DealerAreas').map(f => f[1]).map(c => filtersData.geographical.areas.filter(f => Number(c) === f.id)).map(c => c[0].name).sort();
      groups = queryTab.filter(f => f[0] === 'DealerGroups').map(f => f[1]).map(c => filtersData.geographical.groups.filter(f => Number(c) === f.id)).map(c => c[0].name).sort();
      dealers = queryTab.filter(f => f[0] === 'Birs').map(f => f[1]).map(c => filtersData.geographical.dealers.filter(f => c === f.dealerNumber)).map(c => c[0].name).sort();
      customerTypes = queryTab.filter(f => f[0] === 'CustomerTypes').map(f => f[1]).map(c => filtersData.invoice.customerTypes.filter(f => Number(c) === f.id)).map(c => c[0].name).sort();
      invoices = queryTab.filter(f => f[0] === 'InvoiceTypes').map(f => f[1]).map(c => filtersData.invoice.invoiceTypes.filter(f => Number(c) === f.id)).map(c => c[0].name).sort().sort();
      paymentTypes = queryTab.filter(f => f[0] === 'PaymentTypes').map(f => f[1]).map(c => filtersData.invoice.paymentTypes.filter(f => Number(c) === f.id)).map(c => c[0].name).sort();
      vehicleAges = queryTab.filter(f => f[0] === 'VehicleAges').map(f => f[1]).map(c => filtersData.vehicle.age.filter(f => Number(c) === f.id)).map(c => c[0].name).sort(collator.compare);
      vehicleBrands = queryTab.filter(f => f[0] === 'Brands').map(f => f[1]).map(c => filtersData.vehicle.brands.filter(f => Number(c) === f.id)).map(c => c[0].name).sort();
      vehicleSegments = queryTab.filter(f => f[0] === 'VehicleSegments').map(f => f[1]).map(c => filtersData.vehicle.segments.filter(f => Number(c) === f.id)).map(c => c[0].name).sort();
      vehicleModels = queryTab.filter(f => f[0] === 'Models').map(f => f[1]).map(c => filtersData.vehicle.models.filter(f => c === f.model)).map(c => c[0].model).sort();
      workshopTypes = queryTab.filter(f => f[0] === 'WorkshopTypes').map(f => f[1]).map(c => filtersData.workshopTypes.filter(f => Number(c) === f.id)).map(c => c[0].name).sort();
    }
  }

  return (
      <FiltersWrapper id="filtersBar">
        {queryTab && queryTab.length > 0 &&
          <FiltersTitle><b>{t('filtersSet')}:</b></FiltersTitle>
        }
        {startDate && <Filter><b>{t('dateStart')}</b>: {startDateFormatted}</Filter>}
        {endDate && <Filter><b>{t('dateEnd')}</b>: {endDateFormatted}</Filter>}
        {!onlyDate &&
          <>
            {period && !noTimePeriod && <Filter><b>{t('period')}</b>: {(period === 'week') ? t('weekly') : t('monthly')}</Filter>}
            {cumulative && !noInTotal && <Filter><b>{t('cumulative')}</b>: {(cumulative === 'true') ? 'yes' : 'no'}</Filter>}
            {countries && countries.length > 0 && <Filter><b>{t('countries')}</b>: {countries.join(', ')}</Filter>}
            {areas && areas.length > 0 && <Filter><b>{t('areas')}</b>: {areas.join(', ')}</Filter>}
            {groups && groups.length > 0 && <Filter><b>{t('groups')}</b>: {groups.join(', ')}</Filter>}
            {dealers && dealers.length > 0 && <Filter><b>{t('dealers')}</b>: {dealers.join(', ')}</Filter>}
            {customerTypes && customerTypes.length > 0 && <Filter><b>{t('customerType')}</b>: {customerTypes.join(', ')}</Filter>}
            {invoices && invoices.length > 0 && <Filter><b>{t('invoiceType')}</b>: {invoices.join(', ')}</Filter>}
            {paymentTypes && paymentTypes.length > 0 && <Filter><b>{t('paymentType')}</b>: {paymentTypes.join(', ')}</Filter>}
            {vehicleAges && vehicleAges.length > 0 && <Filter><b>{t('vehicleAge')}</b>: {vehicleAges.join(', ')}</Filter>}
            {vehicleBrands && vehicleBrands.length > 0 && <Filter><b>{t('vehicleBrand')}</b>: {vehicleBrands.join(', ')}</Filter>}
            {vehicleSegments && vehicleSegments.length > 0 && <Filter><b>{t('vehicleSegment')}</b>: {vehicleSegments.join(', ')}</Filter>}
            {vehicleModels && vehicleModels.length > 0 && <Filter><b>{t('vehicleModel')}</b>: {vehicleModels.join(', ')}</Filter>}
            {workshopTypes && workshopTypes.length > 0 && <Filter><b>{t('workshopType')}</b>: {workshopTypes.join(', ')}</Filter>}
          </>
        }
      </FiltersWrapper>
  );
};

export default ReportFiltersBar;
