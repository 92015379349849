import React from 'react';
import styledSC from 'styled-components';
import CustomScrollbars from './CustomScrollbars';
import ProjectTitle from './ProjectTitle';
import ReportFilters from '../reports/ReportFilters';

const Sidebar = styledSC.aside`
  position: absolute;
  left: 0;
  z-index: 1;
  float: left;
  width: 300px;
  height: 100vh;
  margin-right: 20px;
  border-right: 1px solid ${props => props.theme.colors.gray};
  background-color: ${props => props.theme.colors.bgLightGrayColor};
  transition: left .2s ease-in-out;
  
  .hideSideBar & {
    left: -300px;
  }
`;

const SideBar = ({ setQueryFilters, isLoading, cumulativeField = false, cumulativeFieldValue = false, filtersData, setFiltersData, setShowPeriodName, periodName = false, filters = true, categories = false, cumulativeDisabled, onlyDate = false, timePeriod = true }) => {
  const hideShowHandler = () => {
    const bodyClass = document.body.classList;

    if (bodyClass.contains('hideSideBar')) {
      bodyClass.remove('hideSideBar');
    } else {
      bodyClass.add('hideSideBar');
    }
  };

  return (
      <Sidebar>
        <ProjectTitle fnc={hideShowHandler} />
        {filters &&
          <CustomScrollbars style={{ height: 'calc(100% - 61px)' }}>
            <ReportFilters setQueryFilters={setQueryFilters} isLoading={isLoading} filtersData={filtersData} setFiltersData={setFiltersData} cumulativeField={cumulativeField} cumulativeFieldValue={cumulativeFieldValue} setShowPeriodName={setShowPeriodName} periodName={periodName} categories={categories} cumulativeDisabled={cumulativeDisabled} onlyDate={onlyDate} timePeriod={timePeriod} />
          </CustomScrollbars>
        }
      </Sidebar>
  );
};


export default SideBar;
