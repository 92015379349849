import React, { useEffect, useContext } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styledSC from 'styled-components';
import AuthContext from './AuthContext';
import LogoutIcon from '../_icons/Logout';

const Main = styledSC.main`
  display: flex;
  flex-direction: column;
`;

const Nav = styledSC.nav`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: calc(100% - 301px);
  height: 40px;
  background-color: ${props => props.theme.colors.bgLightGrayColor};
  border-top: 1px solid ${props => props.theme.colors.gray};
  transition: width .2s ease-in-out;
  
  .hideSideBar & {
    width: 100%;
  }
`;

const Menu = styledSC.ul`
  display: flex;
`;

const Navlink = styledSC(NavLink)`
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 12px 15px 12px 15px;
  font-size: 16px;
  text-decoration: none;
  color: ${props => props.theme.colors.textColor};
  white-space: nowrap;
  
  &.active, &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.black};
  }
`;

const NavlinkLogout = styledSC(NavLink)`
  position: relative;
  display: flex;
  margin: 0;
  padding: 12px 20px 12px 25px;
  font-size: 16px;
  text-decoration: none;
  color: ${props => props.theme.colors.textColor};
  white-space: nowrap;
  
  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.black};
  }
`;

const Logout = styledSC.li`
  position: absolute;
  right: 0;
  
  .icon {
    margin-right: 10px;
  }
  
  &:hover {
    .icon {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

const Layout = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  return (
      <Main>
        {children}
        {user &&
          <Nav>
            <Menu>
              <li>
                <Navlink to="/" className={({ isActive }) => isActive ? "active" : ""}>
                  {t('reportVisitsTurnoversCountryTitle')}
                </Navlink>
              </li>
              <li>
                <Navlink to="/visitsturnoverdealer" className={({ isActive }) => isActive ? "active" : ""}>
                  {t('reportVisitsTurnoversDealerTitle')}
                </Navlink>
              </li>
              <li>
                <Navlink to="/visitsturnovervehicle" className={({ isActive }) => isActive ? "active" : ""}>
                  {t('reportVisitsTurnoversVehicleTitle')}
                </Navlink>
              </li>
              <li>
                <Navlink to="/visitskpi" className={({ isActive }) => isActive ? "active" : ""}>
                  {t('reportVisitsKpiTitle')}
                </Navlink>
              </li>
              <li>
                <Navlink to="/top20" className={({ isActive }) => isActive ? "active" : ""}>
                  {t('reportTop20')}
                </Navlink>
              </li>
              <li>
                <Navlink to="/crm" className={({ isActive }) => isActive ? "active" : ""}>
                  {t('reportCrm')}
                </Navlink>
              </li>
              <li>
                <Navlink to="/insurance" className={({ isActive }) => isActive ? "active" : ""}>
                  {t('reportInsurance')}
                </Navlink>
              </li>
              <li>
                <Navlink to="/insurancebydealer" className={({ isActive }) => isActive ? "active" : ""}>
                  {t('reportInsuranceDealer')}
                </Navlink>
              </li>
              <li>
                <Navlink to="/categories" className={({ isActive }) => isActive ? "active" : ""}>
                  Categories
                </Navlink>
              </li>
              <Logout>
                <NavlinkLogout className={""} onClick={logout}>
                  <LogoutIcon className='icon' />
                  Log out
                </NavlinkLogout>
              </Logout>
            </Menu>
          </Nav>
        }
      </Main>
  );
};

export default Layout;
