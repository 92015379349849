import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadReport from '../_shared/DownloadReport';
import ReportVisitsTurnoverVehicleData from './ReportVisitsTurnoverVehicleData';
import ReportFiltersBar from './ReportFiltersBar';
import SideBar from '../_shared/SideBar';
import TitleContainer from '../_shared/TitleContainer';
import ReportVisitsTurnoverDealerData from "./ReportVisitsTurnoverDealerData";

const ReportVisitsTurnoverVehicle = () => {
  const { t } = useTranslation();
  const [filtersData, setFiltersData] = useState([]);
  const [queryFilters, setQueryFilters] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(true);

  return (
      <div className='page-wrapper'>
        <SideBar setQueryFilters={setQueryFilters} isLoading={isLoading} filtersData={filtersData} setFiltersData={setFiltersData} cumulativeField={true} cumulativeFieldValue={true} periodName={true} />
        <TitleContainer text={t('reportVisitsTurnoversVehicleTitle')}>
          <DownloadReport  disabled={noData} reportType={3} />
        </TitleContainer>
        <ReportFiltersBar filtersData={filtersData} queryFilters={queryFilters} />
        <ReportVisitsTurnoverVehicleData queryFilters={queryFilters} isLoading={isLoading} setIsLoading={setIsLoading} noData={noData} setNoData={setNoData} />
      </div>
  );
};

export default ReportVisitsTurnoverVehicle;
