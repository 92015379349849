import React from 'react';
import { Scrollbars } from 'rc-scrollbars';

const CustomScrollbars = ({ children, style = {} }) => {
  return (
      <Scrollbars style={style}>
        {children}
      </Scrollbars>
  )
};

export default CustomScrollbars
