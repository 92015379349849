import * as React from 'react';
import styled, {keyframes} from "styled-components";

const Rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Svg = styled.svg`
  animation: ${Rotating} 1s linear infinite;
`;

function SvgLoader(props) {
  return (
      <Svg width={20} height={20} xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d="M10,2 C13.9808302,2 16.9109394,4.24568282 17.7532239,7.80686241 C18.059364,7.09975785 18.3678916,6.29136363 18.6770738,5.38436423 L18.7756584,5.09188612 L19.7243416,5.40811388 C19.2511688,6.82763249 18.7766363,8.02973622 18.2994979,9.01564849 L18.1972136,9.2236068 L18.0878511,9.44233172 L17.2709945,9.60570305 L17.0991322,9.49200952 C16.1033045,8.83323118 15.1092199,8.05863269 14.1166579,7.16842074 L13.7858599,6.86740172 L14.4641401,6.13259828 C15.2382402,6.84715226 16.0113618,7.48729672 16.7833883,8.05314078 C16.0517174,4.93214542 13.5212927,3 10,3 C5.77614237,3 3,5.77614237 3,10 C3,14.2238576 5.77614237,17 10,17 C13.1934919,17 15.5935924,15.4091118 16.549607,12.7418516 L16.6021743,12.5897285 L17.5509917,12.9055539 C16.4930268,16.0839413 13.6735829,18 10,18 C5.22385763,18 2,14.7761424 2,10 C2,5.22385763 5.22385763,2 10,2 Z"></path>
      </Svg>
  );
}

export default SvgLoader;
