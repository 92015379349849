import React, {useContext, useEffect, useState} from 'react';
import CustomScrollbars from '../_shared/CustomScrollbars';
import JwtInterceptor from '../_shared/JwtInterceptors';
import Loader from '../_shared/Loader';
import NoData from '../_shared/NoData';
import ReportCrmTable from './ReportCrmTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Context } from '../../commons/context';
import AuthContext from '../_shared/AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box sx={{ p: 3 }}>
              {children}
            </Box>
        )}
      </div>
  );
};

const ReportCrmData = ({ queryFilters, isLoading, setIsLoading, data, setData, noData, setNoData, refreshTable, setRefreshTable }) => {
  const { logout } = useContext(AuthContext);
  const [dataContext] = useContext(Context);
  const [value, setValue] = useState(0);
  const [rData, setRData] = useState([]);
  const [error, setError] = useState(false);
  const scrollbarHeight = document.getElementById('filtersBar') ? window.innerHeight - (230 + document.getElementById('filtersBar').offsetHeight) : window.innerHeight - 170;

  useEffect(() => {
    const getData = async () => {
      let queryF = dataContext.filters.queryFilters;

      setIsLoading(true);
      setError(false);

      if (!queryF || queryF === '') {
        const now = new Date();
        const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const endDate = new Date(now.setDate(0));
        const startDateQuery = `${startDate.getFullYear()}-${startDate.getMonth()+1}-01`;
        const endDateQuery = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;

        queryF = `StartDate=${startDateQuery}&EndDate=${endDateQuery}&Period=week`;
      }

      JwtInterceptor
          .get(`${API_URL}Data/CrmReport?${queryF}`)
          .then((result) => {
            setData(result.data);
            setIsLoading(false);

            if (result.data.length > 0) {
              setNoData(false);
            } else {
              setNoData(true);
            }

            setRData(result.data);
            setValue(0);
            setRefreshTable(false);
          }).catch(error => {
        console.log('Country Error', error);
        setIsLoading(false);
        setNoData(true);
        setError(true);
        setRefreshTable(false);
        if (error.response.status === 400) logout();
      });
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.filters.queryFilters]);

  useEffect(() => {
    const getData = async () => {
      let queryF = dataContext.filters.queryFilters;

      setIsLoading(true);
      setError(false);

      if (!queryF || queryF === '') {
        const now = new Date();
        const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const endDate = new Date(now.setDate(0));
        const startDateQuery = `${startDate.getFullYear()}-${startDate.getMonth()+1}-01`;
        const endDateQuery = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`;

        queryF = `StartDate=${startDateQuery}&EndDate=${endDateQuery}&Period=week`;
      }

      JwtInterceptor
          .get(`${API_URL}Data/CrmReport?${queryF}`)
          .then((result) => {
            setData(result.data);
            setIsLoading(false);

            if (result.data.length > 0) {
              setNoData(false);
            } else {
              setNoData(true);
            }

            setRData(result.data);
            setValue(0);
            setRefreshTable(false);
          }).catch(error => {
        console.log('Country Error', error);
        setIsLoading(false);
        setNoData(true);
        setError(true);
        setRefreshTable(false);
        if (error.response.status === 400) logout();
      });
    };

    if (refreshTable) getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabsHeader = rData.map((d, i) => <Tab label={d.country} key={`tab_${i}`} />);
  const TabsContent = rData.map((d, i) => {
    return(
        <TabPanel value={value} index={i} key={`tc_${i}`}>
          <CustomScrollbars style={{width: 'auto', height: scrollbarHeight}}>
            <ReportCrmTable tableData={d.campaigns} showPeriodName={!dataContext.filters.cumulative} periodSelect={dataContext.filters.period} setRefreshTable={setRefreshTable} />
          </CustomScrollbars>
        </TabPanel>
    )
  });

  return (
      <div className='page-wrapper'>
        {isLoading ? <Loader /> : (
            <div>
              {noData ? <NoData error={error} /> : (
                  <Box sx={{ width: '100vw', height: '100vh' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {TabsHeader}
                      </Tabs>
                    </Box>
                    {TabsContent}
                  </Box>
              )}
            </div>
        )}
      </div>
  );
};

export default ReportCrmData;
