import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

const ReportVisitsTurnoverCountryTable = ({ tableData, eur, showPeriodName, periodSelect }) => {
  const { t } = useTranslation();
  const months = t('monthsTab', { returnObjects: true });

  const valueColor = val => {
    if (val > 0) {
      return 'f-to-right f-plus';
    } else if (val < 0) {
      return 'f-to-right f-minus';
    } else {
      return 'f-to-right';
    }
  };

  const rows = tableData.periods.map((period, i) => {
    let p = null;

    if (period.period && period.period !== -1) {
      p = (periodSelect === "1") ? `${months[period.period - 1]} / ${period.year}` : `${period.period} / ${period.year}`;
    }

    return (
        <tr key={`tr_${i}`}>
          <td className='t-right-border t-left-border'>{p}</td>
          <td className='f-to-right'><NumericFormat value={period.visits} thousandSeparator=" " displayType="text"/></td>
          <td className='f-to-right'><NumericFormat value={period.visitsM1Y} thousandSeparator=" " displayType="text"/></td>
          <td className={`t-right-border ${valueColor(period.visitsTrend)}`}><NumericFormat value={period.visitsTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
          {!eur && <td className='f-to-right'><NumericFormat value={period.turnover} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {!eur && <td className='f-to-right'><NumericFormat value={period.turnoverM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {!eur && <td className={`t-right-border ${valueColor(period.turnoverTrend)}`}><NumericFormat value={period.turnoverTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
          {eur && <td className='f-to-right'><NumericFormat value={period.turnoverEur} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {eur && <td className='f-to-right'><NumericFormat value={period.turnoverEurM1Y} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {eur && <td className={`t-right-border ${valueColor(period.turnoverEurTrend)}`}><NumericFormat value={period.turnoverEurTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
          {!eur && <td className='f-to-right'><NumericFormat value={period.turnoverPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {!eur && <td className='f-to-right'><NumericFormat value={period.turnoverM1YPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {!eur && <td className={`t-right-border ${valueColor(period.turnoverPerVisitTrend)}`}><NumericFormat value={period.turnoverPerVisitTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
          {eur && <td className='f-to-right'><NumericFormat value={period.turnoverEurPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {eur && <td className='f-to-right'><NumericFormat value={period.turnoverEurM1YPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
          {eur && <td className={`t-right-border ${valueColor(period.turnoverEurPerVisitTrend)}`}><NumericFormat value={period.turnoverEurPerVisitTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
        </tr>
    )
  });

  return (
        <table>
          <thead className="sticky">
            <tr>
              <th className="nowrap bg-default">{showPeriodName ? t('period') : ''}</th>
              <th className="nowrap bg-visits">{t('visitsY')}</th>
              <th className="nowrap bg-visits">{t('visitsY-1')}</th>
              <th className="nowrap bg-visits" dangerouslySetInnerHTML={{__html: t('visitsYvs')}}></th>
              {!eur && <th className="nowrap bg-turnover">{t('turnoverY')}</th>}
              {!eur && <th className="nowrap bg-turnover">{t('turnoverY-1')}</th>}
              {!eur && <th className="nowrap bg-turnover" dangerouslySetInnerHTML={{__html: t('turnoverYvs')}}></th>}
              {eur && <th className="nowrap bg-turnover" dangerouslySetInnerHTML={{__html: t('turnoverYEur')}}></th>}
              {eur && <th className="nowrap bg-turnover" dangerouslySetInnerHTML={{__html: t('turnoverY-1Eur')}}></th>}
              {eur && <th className="nowrap bg-turnover" dangerouslySetInnerHTML={{__html: t('turnoverYvsEur')}}></th>}
              {!eur && <th className="nowrap bg-basket">{t('avBasketY')}</th>}
              {!eur && <th className="nowrap bg-basket">{t('avBasketY-1')}</th>}
              {!eur && <th className="nowrap bg-basket" dangerouslySetInnerHTML={{__html: t('avBasketYvs')}}></th>}
              {eur && <th className="nowrap bg-basket" dangerouslySetInnerHTML={{__html: t('avBasketYEur')}}></th>}
              {eur && <th className="nowrap bg-basket" dangerouslySetInnerHTML={{__html: t('avBasketY-1Eur')}}></th>}
              {eur && <th className="nowrap bg-basket" dangerouslySetInnerHTML={{__html: t('avBasketYvsEur')}}></th>}
            </tr>
          </thead>
          <tbody>
            {rows}
            <tr>
              <td className='f-bold t-top-border t-right-border t-left-border bg-white'>Sum</td>
              <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.visitsSum} thousandSeparator=" " displayType="text"/></td>
              <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.visitsM1YSum} thousandSeparator=" " displayType="text"/></td>
              <td className={`f-bold t-top-border t-right-border ${valueColor(tableData.averageVisitsTrend)} bg-white`}><NumericFormat value={tableData.averageVisitsTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>
              {!eur && <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.turnoverSum} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
              {!eur && <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.turnoverM1YSum} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
              {!eur && <td className={`f-bold t-top-border t-right-border ${valueColor(tableData.averageTurnoverTrend)} bg-white`}><NumericFormat value={tableData.averageTurnoverTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
              {eur && <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.turnoverEurSum} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
              {eur && <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.turnoverEurM1YSum} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
              {eur && <td className={`f-bold t-top-border t-right-border ${valueColor(tableData.averageTurnoverEurTrend)} bg-white`}><NumericFormat value={tableData.averageTurnoverEurTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
              {!eur && <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.averageTurnoverPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
              {!eur && <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.averageTurnoverM1YPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
              {!eur && <td className={`f-bold t-top-border t-right-border ${valueColor(tableData.averageTurnoverPerVisitTrend)} bg-white`}><NumericFormat value={tableData.averageTurnoverPerVisitTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
              {eur && <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.averageTurnoverEurPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
              {eur && <td className='f-bold f-to-right t-top-border bg-white'><NumericFormat value={tableData.averageTurnoverEurM1YPerVisit} thousandSeparator=" " displayType="text" decimalScale={0}/></td>}
              {eur && <td className={`f-bold t-top-border t-right-border ${valueColor(tableData.averageTurnoverEurPerVisitTrend)} bg-white`}><NumericFormat value={tableData.averageTurnoverEurPerVisitTrend} thousandSeparator=" " displayType="text" decimalScale={1} decimalSeparator=","/> %</td>}
            </tr>
          </tbody>
        </table>
  );
};

export default ReportVisitsTurnoverCountryTable;
