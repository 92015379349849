import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronLeft from '../_icons/ChevronLeft';
import CustomButtonEmpty from '../_shared/CustomButtonEmpty';
import Delete from '../_icons/Delete';
import Dialog from '@mui/material/Dialog';
import Loader from '../_icons/Loader';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import styledSC from "styled-components";
import JwtInterceptor from "../_shared/JwtInterceptors";

const API_URL = process.env.REACT_APP_API_URL;

const Arrow = styledSC(ChevronLeft)`
  transform: rotate(-90deg);
  top: 5px;
  left: 4px;
  
  &:hover {
    cursor: pointer;
  }
  
  &.show {
    transform: rotate(90deg);
  }
`;

const ArrowUp = styledSC(ChevronLeft)`
  transform: rotate(90deg);
  position: absolute;
  top: -2px;
  left: 3px;
  
  &:hover {
    cursor: pointer;
  }
  
  &.show {
    transform: rotate(90deg);
  }
`;

const ArrowDown = styledSC(ChevronLeft)`
  transform: rotate(-90deg);
  position: absolute;
  bottom: -2px;
  left: 4px;
  
  &:hover {
    cursor: pointer;
  }
  
  &.show {
    transform: rotate(90deg);
  }
`;

const DeleteIcon = styledSC(Delete)`
  position: absolute;
  top: 5px;
  left: 4px;
  
  &:hover {
    cursor: pointer;
  }
`;

const LoaderIcon = styledSC(Loader)`
  position: absolute;
  top: 7px;
  left: 7px;
`;

const DialogTitle = styledSC.h3`
  padding: 20px;
  font-size: 18px;
  color: #000;
`;

const ButtonContainer = styledSC.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 20px;
`

const SpanContainer = styledSC.span`
  display: block;
`;

const AlertError = React.forwardRef(function Alert(props, ref,) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SimpleDialog = ({onClose, open, name, id}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle><b>{t('remove')} {name}?</b></DialogTitle>
      <ButtonContainer>
        <CustomButtonEmpty text={t('yes')} onClickFnc={() => onClose(id)} />
        <CustomButtonEmpty text={t('no')} onClickFnc={onClose} />
      </ButtonContainer>
    </Dialog>
  );
}
const Row = ({ rowData, keyV, setDialogOpen, deleteId, setDeleteId, setDeleteName, isLoading, allRows, moveId, setMoveId, setMoveWithId, isMoveLoading }) => {
  const [showModels, setShowModels] = useState(false);
  let models;
  const modelsHandler = () => {
    setShowModels(!showModels);
  };

  const deleteHandler = (id, name) => {
    setDeleteId(id);
    setDeleteName(name);
    setDialogOpen(true);
  }

  const moveHandler = (e, id, direction) => {
    const parent = e.currentTarget.parentNode.parentNode;
    setMoveId(id);

    if (direction === 'up') {
      setMoveWithId(parent.previousElementSibling.getAttribute('id').substring(3));
    } else {
      setMoveWithId(parent.nextElementSibling.getAttribute('id').substring(3));
    }
  }

  models = rowData.models.map((model, i) => {
    return (
        <tr key={`mtr_${model.name}_${i}`}>
          <td className='t-left-border'>{rowData.name}</td>
          <td className='t-left-border'>{model.sentOn}</td>
          <td className='t-left-border'>{model.model}</td>
          <td className='f-to-right t-left-border'>{model.contacts}</td>
          <td className='f-to-right t-left-border'>{model.conversion}</td>
          <td className='f-to-right t-left-border'>{model.conversionRate}</td>
          <td className='f-to-right t-left-border'></td>
          <td className='f-to-right t-left-border'></td>
          <td className='f-to-right t-left-border t-right-border'></td>
        </tr>
    )
  });

  return (
      <>
        <tr key={`tr_${keyV}`} id={`tr_${rowData.id}`}>
          <td className='t-left-border'><b>{rowData.name}</b></td>
          <td className='t-left-border'><b>{rowData.sentOn}</b></td>
          <td className='t-left-border'>&nbsp;</td>
          <td className='f-to-right t-left-border'><b>{rowData.contacts}</b></td>
          <td className='f-to-right t-left-border'><b>{rowData.conversion}</b></td>
          <td className='f-to-right t-left-border'><b>{rowData.conversionRate}</b></td>
          <td className='f-to-right t-left-border t-relative' width={12}>
            {isMoveLoading && moveId === rowData.id ?
              <LoaderIcon />
            :
              <>
                {keyV > 0 && <SpanContainer onClick={(e) => moveHandler(e, rowData.id, 'up')}><ArrowUp /></SpanContainer>}
                {keyV < allRows - 1 && <SpanContainer onClick={(e) => moveHandler(e, rowData.id, 'down')}><ArrowDown /></SpanContainer>}
              </>
            }
          </td>
          <td className='f-to-right t-left-border t-relative' width={12}>
            {isLoading && deleteId === rowData.id ? <LoaderIcon /> : <DeleteIcon onClick={() => deleteHandler(rowData.id, rowData.name)} />}
          </td>
          <td className='f-to-right t-left-border t-right-border t-relative' width={10}>
            <Arrow onClick={() => modelsHandler()} className={`t-absolute ${showModels ? 'show' : ''}`} />
          </td>
        </tr>
        {showModels && models}
      </>
  )
};

const ReportCrmTable = ({ tableData, setRefreshTable }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [deleteId, setDeleteId] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const [deleteError, setDeleteError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [moveId, setMoveId] = useState(false);
  const [moveWithId, setMoveWithId] = useState(false);
  const [moveError, setMoveError] = useState(false);
  const [isMoveLoading, setIsMoveLoading] = useState(false);

  useEffect(() => {
    const deleteData = async () => {
      setIsLoading(true);
      setDeleteError(false);

      JwtInterceptor
          .delete(`${API_URL}CrmCampaigns/${selectedValue}`)
          .then((result) => {
            setIsLoading(false);
            setRefreshTable(true);
            setSelectedValue('');
          }).catch(error => {
            console.log(error);
            setIsLoading(false);
            setDeleteError(true);
            setSelectedValue('');
          });
    };

    if (selectedValue && selectedValue !== '') deleteData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    const moveRow = async () => {
      setMoveError(false);
      setIsMoveLoading(true);

      JwtInterceptor
          .get(`${API_URL}CrmCampaigns/reorder/${moveId}/${moveWithId}`)
          .then((result) => {
            setIsMoveLoading(false);
            setRefreshTable(true);
            setMoveId(false);
            setMoveWithId(false);
          }).catch(error => {
            console.log(error);
            setIsMoveLoading(false);
            setMoveError(true);
            setMoveId(false);
            setMoveWithId(false);
          });
    };

    if (moveId && moveWithId) moveRow();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveId, moveWithId]);

  const rows = tableData.map((row, i) => <Row rowData={row} keyV={i} key={`row_${i}`} setDialogOpen={setOpen} deleteId={deleteId} setDeleteId={setDeleteId} setDeleteName={setDeleteName} isLoading={isLoading} allRows={tableData.length} moveId={moveId} setMoveId={setMoveId} setMoveWithId={setMoveWithId} isMoveLoading={isMoveLoading} />);

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleCloseError = () => {
    setDeleteError(false);
  };

  const handleCloseMoveError = () => {
    setMoveError(false);
  };

  return (
    <>
      <table>
        <thead className="sticky">
        <tr>
          <th className="nowrap bg-default">{t('campaignName')}</th>
          <th className="nowrap bg-default">{t('shippingDate')}</th>
          <th className="nowrap bg-default" width="160">{t('vehicleModel')}</th>
          <th className="nowrap bg-default">{t('contacts')}</th>
          <th className="nowrap bg-default">{t('conversion')}</th>
          <th className="nowrap bg-default">{t('conversionRate')}</th>
          <th className="nowrap bg-default">&nbsp;</th>
          <th className="nowrap bg-default">&nbsp;</th>
          <th className="nowrap bg-default">&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
      <SimpleDialog open={open} onClose={handleClose} name={deleteName} id={deleteId} />
      {deleteError &&
        <Snackbar open={deleteError} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <AlertError severity="error" sx={{ width: '100%' }}><p>{t('error')}</p> <p>{t('error2')}</p></AlertError>
        </Snackbar>
      }
      {moveError &&
        <Snackbar open={moveError} autoHideDuration={6000} onClose={handleCloseMoveError} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <AlertError severity="error" sx={{ width: '100%' }}><p>{t('error')}</p> <p>{t('error2')}</p></AlertError>
        </Snackbar>
      }
    </>
  );
};

export default ReportCrmTable;
