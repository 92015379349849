import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styledSC from "styled-components";
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import JwtInterceptor from '../_shared/JwtInterceptors';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '../_icons/Close';
import CustomButton from "../_shared/CustomButton";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Loader from "../_icons/Loader";
import MenuItem from '@mui/material/MenuItem';
import PageTitle from "../_shared/PageTitle";
import Popper from '@mui/material/Popper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import StylesSelect from "../../commons/_muiOverride/StylesSelect";
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Context } from '../../commons/context';
import CustomButtonEmpty from "../_shared/CustomButtonEmpty";

const API_URL = process.env.REACT_APP_API_URL;

const Container = styledSC.div`
  width: 260px;
  padding: 20px;
`;

const Form = styledSC.div`
  width: 100%;
  margin-top: 25px;
  text-align: left;
`;

const FloatContainer = styledSC.div`
  margin-bottom: 10px;
  height: 48px;
`;

const FormControlLabelCh = styled(FormControlLabel)`
  min-width: 65px;
`;

const FloatContainerAutocomplete = styledSC.div`
  margin-bottom: 10px;
`;

const FloatContainerSwitch = styledSC.div`
  margin: 10px 0;
`;

const CumulativeWrapper = styledSC.div`
  overflow: hidden;
`;

const SelectField = styled(Select)`
  ${StylesSelect}
`;

const TypographyS = styled(Typography)`
  font-family: 'NouvelRRegular';
`;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#efdf00',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#efdf00',
    boxSizing: 'border-box',
  },
}));

const PopperMy = function (props) {
  return <Popper {...props} style={{ position: 'fixed' }}  placement="bottom-start" />;
};

const ReportFilters = ({ setQueryFilters, isLoading, cumulativeField = false, cumulativeFieldValue = false, filtersData, setFiltersData, categories = false, cumulativeDisabled = false, onlyDate = false, timePeriod = true }) => {
  const { t } = useTranslation();
  const [data, setData] = useContext(Context);
  const now = new Date();
  const prevMonth = new Date(now.setDate(0));
  const [filtersDataExist, setFiltersDataExist] = useState(false);
  const [startDate, setStartDate] = useState(data.filters.startDate ? dayjs(data.filters.startDate) : dayjs(prevMonth));
  const [endDate, setEndDate] = useState(data.filters.endDate ? dayjs(data.filters.endDate) : dayjs(prevMonth));
  const [period, setPeriod] = useState(data.filters.period ? Number(data.filters.period) : 2);
  const [countries, setCountries] = useState(data.filters.countries);
  const [areas, setAreas] = useState(data.filters.areas);
  const [areasValues, setAreasValues] = useState([]);
  const [groups, setGroups] = useState(data.filters.groups);
  const [groupsValues, setGroupsValues] = useState([]);
  const [dealers, setDealers] = useState(data.filters.dealers);
  const [dealersValues, setDealersValues] = useState([]);
  const [customerTypes, setCustomerTypes] = useState(data.filters.customerTypes);
  const [invoiceTypes, setInvoiceTypes] = useState(data.filters.invoiceTypes);
  const [paymentTypes, setPaymentTypes] = useState(data.filters.paymentTypes);
  const [vehicleAge, setVehicleAge] = useState(data.filters.vehicleAge);
  const [vehicleAgeType, setVehicleAgeType] = useState(data.filters.vehicleAgeType);
  const [vehicleAgeAll, setVehicleAgeAll] = useState(data.filters.vehicleAgeAll);
  const [vehicleBrands, setVehicleBrands] = useState(data.filters.vehicleBrands);
  const [vehicleSegments, setVehicleSegments] = useState(data.filters.vehicleSegments);
  const [vehicleModels, setVehicleModels] = useState(data.filters.vehicleModels);
  const [vehicleModelsValues, setVehicleModelsValues] = useState([]);
  const [workshopTypes, setWorkshopTypes] = useState(data.filters.workshopTypes);
  const [cumulative, setCumulative] = useState(data.filters.cumulative !== null ? data.filters.cumulative : cumulativeFieldValue);
  const [categoriesData, setCategoriesData] = useState([]);
  const [cat, setCat] = useState(data.filters.categories);
  let checkboxes;

  useEffect(() => {
    const getFiltersData = async () => {
      JwtInterceptor
          .get(`${API_URL}Filters`)
          .then((result) => {
            setFiltersData(result.data);
            setFiltersDataExist(true);
            setAreasValues(result.data.geographical.areas);
            setGroupsValues(result.data.geographical.groups);
            setDealersValues(result.data.geographical.dealers);
            setVehicleModelsValues(result.data.vehicle.models);
          })
          .catch(function(error) {
            console.log(error);
            setFiltersDataExist(false);
          });
    };

    const getCategoriesData = async () => {
      JwtInterceptor
          .get(`${API_URL}Categories`)
          .then((result) => {
            setCategoriesData(result.data);
            setData({
              type: 'SET_FILTERS',
              payload: {
                ...data.filters,
                categoriesData: result.data
              },
            });
          }).catch(error => {
            console.log('Category', error);
      });
    };

    getFiltersData();

    if (categories) {
      getCategoriesData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filtersDataExist) {
      if (countries.length > 0) {
        setAreasValues(filtersData.geographical.areas.filter(area => countries.includes(area.countryId)));
        setGroupsValues(filtersData.geographical.groups.filter(group => countries.includes(group.countryId)));
        setDealersValues(filtersData.geographical.dealers.filter(dealer => countries.includes(dealer.countryId)));
      } else {
        if (filtersData && filtersData.geographical) {
          setAreasValues(filtersData.geographical.areas);
          setGroupsValues(filtersData.geographical.groups);
          setDealersValues(filtersData.geographical.dealers);
        }
      }
      setAreas([]);
      setGroups([]);
      setDealers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  useEffect(() => {
    if (filtersDataExist) {
      if (areas.length > 0) {
        if (countries.length > 0) {
          setGroupsValues(filtersData.geographical.groups.filter(group => countries.includes(group.countryId)));
          setDealersValues(filtersData.geographical.dealers.filter(dealer => countries.includes(dealer.countryId)));
        } else {
          setGroupsValues(filtersData.geographical.groups);
          setDealersValues(filtersData.geographical.dealers);
        }
      } else {
        if (filtersData && filtersData.geographical) {
          if (countries.length > 0) {
            setGroupsValues(filtersData.geographical.groups.filter(group => countries.includes(group.countryId)));
            setDealersValues(filtersData.geographical.dealers.filter(dealer => countries.includes(dealer.countryId)));
          } else {
            setGroupsValues(filtersData.geographical.groups);
            setDealersValues(filtersData.geographical.dealers);
          }
        }
      }
      setGroups([]);
      setDealers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas]);

  useEffect(() => {
    if (filtersDataExist) {
      if (groups.length > 0) {
        const groupsId = groups.map(group => group.id);

        if (countries.length > 0 && areas.length > 0) {
          setDealersValues(filtersData.geographical.dealers.filter(dealer => (countries.includes(dealer.countryId) && areas.includes(dealer.areaId) && groupsId.includes(dealer.groupId))));
        } else if (countries.length > 0 && areas.length <= 0) {
          setDealersValues(filtersData.geographical.dealers.filter(dealer => (countries.includes(dealer.countryId) && groupsId.includes(dealer.groupId))));
        } else if (countries.length <= 0 && areas.length > 0) {
          setDealersValues(filtersData.geographical.dealers.filter(dealer => (areas.includes(dealer.areaId) && groupsId.includes(dealer.groupId))));
        } else {
          setDealersValues(filtersData.geographical.dealers.filter(dealer => (groupsId.includes(dealer.groupId))));
        }
      } else {
        if (filtersData && filtersData.geographical) {
          if (countries.length > 0 && areas.length > 0) {
            setDealersValues(filtersData.geographical.dealers.filter(dealer => (countries.includes(dealer.countryId) && areas.includes(dealer.areaId))));
          } else if (countries.length > 0 && areas.length <= 0) {
            setDealersValues(filtersData.geographical.dealers.filter(dealer => (countries.includes(dealer.countryId))));
          } else if (countries.length <= 0 && areas.length > 0) {
            setDealersValues(filtersData.geographical.dealers.filter(dealer => (areas.includes(dealer.areaId))));
          } else {
            setDealersValues(filtersData.geographical.dealers);
          }
        }
      }
      setDealers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    if (filtersDataExist) {
      if (vehicleBrands.length > 0) {
        setVehicleModelsValues(filtersData.vehicle.models.filter(model => vehicleBrands.includes(model.brandId)));
      } else {
        if (filtersData && filtersData.vehicle) {
          setVehicleModelsValues(filtersData.vehicle.models);
        }
      }
      setVehicleModels([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleBrands]);

  useEffect(() => {
    setCat(data.filters.categories);
  }, [data.filters.categories]);

  useEffect(() => {
    if (data.filters.changeCat) {
      onSubmit();
    }
  }, [cat]);

  const onSubmit = async () => {
    const endDateJS = new Date(endDate.$y, endDate.$M + 1, 0);
    const qStartDate = `${startDate.$y}-${startDate.$M + 1}-1`;
    const qEndDate = `${endDate.$y}-${endDate.$M + 1}-${endDateJS.getDate()}`;
    const qPeriod = period === '1' ? 'month' : 'week';
    let query;

    query = `StartDate=${qStartDate}&EndDate=${qEndDate}&Period=${qPeriod}`;

    if (categories && cat.length > 0) {
      const qCategories = cat.map(c => `&Categories=${c}`);
      query += qCategories.join('');
    }

    if (cumulativeField) {
      const qCumulative = `&Cumulative=${cumulative}`;
      query += qCumulative;
    }

    if (countries.length > 0) {
      const qCountries = countries.map(country => `&Countries=${country}`);
      query += qCountries.join('');
    }

    if (areas.length > 0) {
      const qAreas = areas.map(area => `&DealerAreas=${area}`);
      query += qAreas.join('');
    }

    if (groups.length > 0) {
      const qGroups = groups.map(group => `&DealerGroups=${group.id}`);
      query += qGroups.join('');
    }

    if (dealers.length > 0) {
      const qDealers = dealers.map(dealer => `&Birs=${dealer.dealerNumber}`);
      query += qDealers.join('');
    }

    if (customerTypes.length > 0) {
      const qCustomerTypes = customerTypes.map(type => `&CustomerTypes=${type}`);
      query += qCustomerTypes.join('');
    }

    if (invoiceTypes.length > 0) {
      const qInvoiceTypes = invoiceTypes.map(type => `&InvoiceTypes=${type}`);
      query += qInvoiceTypes.join('');
    }

    if (paymentTypes.length > 0) {
      const qPaymentTypes = paymentTypes.map(type => `&PaymentTypes=${type}`);
      query += qPaymentTypes.join('');
    }

    if (workshopTypes.length > 0) {
      const qWorkshopTypes = workshopTypes.map(type => `&WorkshopTypes=${type}`);
      query += qWorkshopTypes.join('');
    }

    if (vehicleAge.length > 0) {
      const qVehicleAge = vehicleAge.map(age => `&VehicleAges=${age}`);
      query += qVehicleAge.join('');

      if (vehicleAgeType) {
        const qVehicleAgeType = `&VehicleAgeType=2`;
        query += qVehicleAgeType;
      } else {
        const qVehicleAgeType = `&VehicleAgeType=1`;
        query += qVehicleAgeType;
      }
    }

    if (vehicleSegments.length > 0) {
      const qVehicleSegments = vehicleSegments.map(segment => `&VehicleSegments=${segment}`);
      query += qVehicleSegments.join('');
    }

    if (vehicleBrands.length > 0) {
      const qVehicleBrands = vehicleBrands.map(brand => `&Brands=${brand}`);
      query += qVehicleBrands.join('');
    }

    if (vehicleModels.length > 0) {
      const qModels = vehicleModels.map(model => `&Models=${model}`);
      query += qModels.join('');
    }

    setQueryFilters(query);

    setData({
      type: 'SET_FILTERS',
      payload: {
        ...data.filters,
        startDate,
        endDate,
        period,
        categories: cat,
        cumulative,
        countries,
        areas,
        groups,
        dealers,
        customerTypes,
        invoiceTypes,
        paymentTypes,
        vehicleAge,
        vehicleAgeAll,
        vehicleBrands,
        vehicleSegments,
        vehicleModels,
        workshopTypes,
        queryFilters: query,
        changeCat: false
      },
    });
  };

  const clearFiltersHandler = () => {
    const now = new Date();
    const prevMonth = new Date(now.setDate(0));

    setStartDate(dayjs(prevMonth));
    setEndDate(dayjs(prevMonth));
    setPeriod(2);
    setCountries([]);
    setAreas([]);
    setAreasValues([]);
    setGroups([]);
    setGroupsValues([]);
    setDealers([]);
    setDealersValues([]);
    setCustomerTypes([]);
    setInvoiceTypes([1]);
    setPaymentTypes([]);
    setVehicleAge([]);
    setVehicleAgeType(false);
    setVehicleAgeAll(false);
    setVehicleBrands([]);
    setVehicleSegments([]);
    setVehicleModels([]);
    setVehicleModelsValues([]);
    setWorkshopTypes([]);
    setCumulative(true);
    setCat([]);

    setQueryFilters('');

    setData({
      type: 'SET_FILTERS',
      payload: {
        ...data.filters,
        startDate: dayjs(prevMonth),
        endDate: dayjs(prevMonth),
        period: 2,
        categories: [],
        cumulative: true,
        countries: [],
        areas: [],
        groups: [],
        dealers: [],
        customerTypes: [],
        invoiceTypes: [1],
        paymentTypes: [],
        vehicleAge: [],
        vehicleAgeType: false,
        vehicleAgeAll: false,
        vehicleBrands: [],
        vehicleSegments: [],
        vehicleModels: [],
        workshopTypes: [],
        queryFilters: ''
      },
    });
  };

  const renderValueHandler = (selected, data, model = false) => {
    let names = [];

    if (model) {
      names = selected.map(s => data.filter(f => f.model === s)).map(s => s[0].model);
    } else {
      names = selected.map(s => data.filter(f => f.id === s)).map(s => s[0].name);
    }
    return names.join(", ");
  };

  const setGroupsHandler = (e, value) => {
    setGroups(value);
  };

  const setDealersHandler = (e, value) => {
    setDealers(value);
  };

  const ageHandler = (e) => {
    const target = e.currentTarget;

    if (target.checked) {
      setVehicleAge(arr => [...arr, Number(target.value)]);
      setData({
        type: 'SET_FILTERS',
        payload: {
          ...data.filters,
          vehicleAge: vehicleAge,
        },
      });
    } else {
      const newVehicleAge = vehicleAge.filter(filter => filter !== Number(target.value));
      setVehicleAge(newVehicleAge);
      setVehicleAgeAll(false);
      setData({
        type: 'SET_FILTERS',
        payload: {
          ...data.filters,
          vehicleAge: vehicleAge,
          vehicleAgeAll: false,
        },
      });
    }
  };

  const ageAllHandler = (e) => {
    const chk = e.currentTarget;
    const parent = chk.parentNode.parentNode.parentNode;
    const chks = parent.querySelectorAll("input[type='checkbox']:not(.MuiSwitch-input)");
    const isChecked = chk.checked;

    if (isChecked) {
      setVehicleAgeAll(true);
      setData({
        type: 'SET_FILTERS',
        payload: {
          vehicleAgeAll: true,
        },
      });
      for (let i = 0; i < chks.length; i++) {
        if (chks[i].checked !== true) chks[i].click();
      }
    } else {
      setVehicleAgeAll(false);
      setData({
        type: 'SET_FILTERS',
        payload: {
          vehicleAgeAll: false,
        },
      });
      for (let i = 0; i < chks.length; i++) {
        if (chks[i].checked === true) chks[i].click();
      }
      setVehicleAge([]);
    }
  };

  const vehicleAgeTypeHandler = (e) => {
    setVehicleAgeType(e.currentTarget.checked)
  };

  if (filtersDataExist && filtersData.vehicle.age.length > 0) {
    checkboxes = filtersData.vehicle.age.map((a, i) => {
      return(
          <FormControlLabelCh control={<Checkbox value={a.id} name={`chk_age_${a.name}`} onChange={ageHandler} />} label={a.name} checked={(vehicleAge.length > 0 && vehicleAge.includes(a.id))} key={`chk_label_${i}`} />
      );
    })
  }

  return (
      <Container>
        <PageTitle title={t('filters')} noPadding={true} />
        <Form>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <TypographyS>{t('filtersCategoryBasic')}</TypographyS>
            </AccordionSummary>
            <AccordionDetails>
              <FloatContainer>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                  <DatePicker
                      label={t('dateStart')}
                      views={['year', 'month']}
                      slotProps={{ textField: { variant: 'standard' } }}
                      onChange={(date) => setStartDate(date)}
                      value={startDate}
                      sx={{ width: 'calc(100% - 1px)' }}
                      disableFuture={true}
                      minDate={dayjs().year(2020).month(0)}
                      maxDate={dayjs(new Date())}
                  />
                </LocalizationProvider>
              </FloatContainer>

              <FloatContainer>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                  <DatePicker
                      label={t('dateEnd')}
                      views={['year', 'month']}
                      slotProps={{ textField: { variant: 'standard' } }}
                      onChange={(date) => setEndDate(date)}
                      value={endDate}
                      sx={{ width: 'calc(100% - 1px)' }}
                      minDate={dayjs().year(2020).month(0)}
                      maxDate={dayjs(new Date())}
                  />
                </LocalizationProvider>
              </FloatContainer>

              {!onlyDate && timePeriod &&
                <FloatContainer>
                  <FormControl variant="standard" sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-standard-label">{t('period')}</InputLabel>
                    <SelectField
                        value={period}
                        onChange={(e) => setPeriod(e.target.value)}
                    >
                      <MenuItem value="2">{t('weekly')}</MenuItem>
                      <MenuItem value="1">{t('monthly')}</MenuItem>
                    </SelectField>
                  </FormControl>
                </FloatContainer>
              }

              {categories && categoriesData.length > 0 &&
                <FloatContainer>
                  <FormControl variant="standard" sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-standard-label">{t('categories')}</InputLabel>
                    <SelectField
                        value={cat}
                        onChange={(e) => setCat(e.target.value)}
                        renderValue={(selected) => renderValueHandler(selected, categoriesData)}
                        multiple
                    >
                      {categoriesData.map((c) => (
                          <MenuItem value={c.id} key={`country_${c.id}`}>
                            <Checkbox checked={cat.indexOf(c.id) > -1} />
                            {c.name}
                          </MenuItem>
                      ))}
                    </SelectField>
                  </FormControl>
                </FloatContainer>
              }

              {cumulativeField &&
                <CumulativeWrapper>
                  <FormControlLabel control={
                    <Checkbox name='cumulative' checked={cumulative} onChange={(e) => setCumulative(e.target.checked)} disabled={cumulativeDisabled} />
                  } label={t('cumulative')} />
                </CumulativeWrapper>
              }

            </AccordionDetails>
          </Accordion>

          {!onlyDate &&
            <>
              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                  <TypographyS>{t('filtersCategoryGeographical')}</TypographyS>
                </AccordionSummary>
                <AccordionDetails>
                    {filtersDataExist && filtersData.geographical.countries.length > 0 &&
                      <FloatContainer>
                        <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                          <InputLabel id="demo-simple-select-standard-label">{t('countries')}</InputLabel>
                          <SelectField
                              value={countries}
                              onChange={(e) => setCountries(e.target.value)}
                              renderValue={(selected) => renderValueHandler(selected, filtersData.geographical.countries)}
                              multiple
                          >
                            {filtersData.geographical.countries.map((country) => (
                              <MenuItem value={country.id} key={`country_${country.id}`}>
                                <Checkbox checked={countries.indexOf(country.id) > -1} />
                                {country.name}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </FormControl>
                      </FloatContainer>
                    }

                    {filtersDataExist &&
                      <FloatContainer>
                        <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                          <InputLabel id="demo-simple-select-standard-label">{t('areas')}</InputLabel>
                          <SelectField
                              value={areas}
                              onChange={(e) => setAreas(e.target.value)}
                              multiple
                              renderValue={(selected) => renderValueHandler(selected, areasValues)}
                          >
                            {areasValues.length > 0 && areasValues.map((area) => (
                                <MenuItem value={area.id} key={`area_${area.id}`}>
                                  <Checkbox checked={areas.indexOf(area.id) > -1} />
                                  {area.name}
                                </MenuItem>
                            ))}
                          </SelectField>
                        </FormControl>
                      </FloatContainer>
                    }

                    {filtersDataExist &&
                      <FloatContainerAutocomplete>
                        <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                          <Autocomplete
                              multiple
                              limitTags={2}
                              options={groupsValues}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              onChange={setGroupsHandler}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              PopperComponent={PopperMy}
                              renderOption={(props, option, { selected }) => (
                                  <li {...props} key={option.id}>
                                    <Checkbox
                                        checked={selected}
                                    />
                                    {option.name}
                                  </li>
                              )}
                              style={{ width: 'calc(100% - 1px)' }}
                              renderInput={(params) => (
                                  <TextField {...params} label={t('groups')} variant="standard" />
                              )}
                              value={groups || null}
                          />
                        </FormControl>
                      </FloatContainerAutocomplete>
                    }

                    {filtersDataExist &&
                      <FloatContainerAutocomplete>
                        <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                          <Autocomplete
                              multiple
                              limitTags={2}
                              options={dealersValues}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              onChange={setDealersHandler}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              PopperComponent={PopperMy}
                              renderOption={(props, option, { selected }) => (
                                  <li {...props} key={option.id}>
                                    <Checkbox
                                        checked={selected}
                                    />
                                    {option.name}
                                  </li>
                              )}
                              style={{ width: 'calc(100% - 1px)' }}
                              renderInput={(params) => (
                                  <TextField {...params} label={t('dealers')} variant="standard" />
                              )}
                              value={dealers || null}
                          />
                        </FormControl>
                      </FloatContainerAutocomplete>
                    }
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                  <TypographyS>{t('filtersCategoryInvoice')}</TypographyS>
                </AccordionSummary>
                <AccordionDetails>
                  {filtersDataExist && filtersData.invoice.customerTypes.length > 0 &&
                    <FloatContainer>
                      <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                        <InputLabel id="demo-simple-select-standard-label">{t('customerType')}</InputLabel>
                        <SelectField
                            value={customerTypes}
                            onChange={(e) => setCustomerTypes(e.target.value)}
                            multiple
                            renderValue={(selected) => renderValueHandler(selected, filtersData.invoice.customerTypes)}
                        >
                          {filtersData.invoice.customerTypes.map((customer) => (
                              <MenuItem value={customer.id} key={`customer_${customer.id}`}>
                                <Checkbox checked={customerTypes.indexOf(customer.id) > -1} />
                                {customer.name}
                              </MenuItem>
                          ))}
                        </SelectField>
                      </FormControl>
                    </FloatContainer>
                  }

                  {filtersDataExist && filtersData.invoice.invoiceTypes.length > 0 &&
                    <FloatContainer>
                      <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                        <InputLabel id="demo-simple-select-standard-label">{t('invoiceType')}</InputLabel>
                        <SelectField
                            value={invoiceTypes}
                            onChange={(e) => setInvoiceTypes(e.target.value)}
                            multiple
                            renderValue={(selected) => renderValueHandler(selected, filtersData.invoice.invoiceTypes)}
                        >
                          {filtersData.invoice.invoiceTypes.map((invoice) => (
                              <MenuItem value={invoice.id} key={`invoice_${invoice.id}`}>
                                <Checkbox checked={invoiceTypes.indexOf(invoice.id) > -1} />
                                {invoice.name}
                              </MenuItem>
                          ))}
                        </SelectField>
                      </FormControl>
                    </FloatContainer>
                  }

                  {filtersDataExist && filtersData.invoice.paymentTypes.length > 0 &&
                    <FloatContainer>
                      <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                        <InputLabel id="demo-simple-select-standard-label">{t('paymentType')}</InputLabel>
                        <SelectField
                            value={paymentTypes}
                            onChange={(e) => setPaymentTypes(e.target.value)}
                            multiple
                            renderValue={(selected) => renderValueHandler(selected, filtersData.invoice.paymentTypes)}
                        >
                          {filtersData.invoice.paymentTypes.map((payment) => (
                              <MenuItem value={payment.id} key={`payment_${payment.id}`}>
                                <Checkbox checked={paymentTypes.indexOf(payment.id) > -1} />
                                {payment.name}
                              </MenuItem>
                          ))}
                        </SelectField>
                      </FormControl>
                    </FloatContainer>
                 }
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                  <TypographyS>{t('filtersCategoryVehicle')}</TypographyS>
                </AccordionSummary>
                <AccordionDetails>
                  <InputLabel id="chxAge" shrink={true}>{t('vehicleAge')}</InputLabel>
                  <FormControlLabel control={<Checkbox name="chk_age_all" onChange={ageAllHandler} />} label={t('all')} checked={vehicleAgeAll} /><br />
                  {checkboxes}

                  <FloatContainerSwitch>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>VehAge</Typography>
                      <AntSwitch defaultChecked={vehicleAgeType} checked={vehicleAgeType} inputProps={{ 'aria-label': 'ant design' }} onChange={vehicleAgeTypeHandler} />
                      <Typography>VehAge1Jan</Typography>
                    </Stack>
                  </FloatContainerSwitch>

                  {filtersDataExist && filtersData.vehicle.brands.length > 0 &&
                    <FloatContainer>
                      <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                        <InputLabel id="demo-simple-select-standard-label">{t('vehicleBrand')}</InputLabel>
                        <SelectField
                            value={vehicleBrands}
                            onChange={(e) => setVehicleBrands(e.target.value)}
                            multiple
                            renderValue={(selected) => renderValueHandler(selected, filtersData.vehicle.brands)}
                        >
                          {filtersData.vehicle.brands.map((brand) => (
                              <MenuItem value={brand.id} key={`brand_${brand.id}`}>
                                <Checkbox checked={vehicleBrands.indexOf(brand.id) > -1} />
                                {brand.name}
                              </MenuItem>
                          ))}
                        </SelectField>
                      </FormControl>
                    </FloatContainer>
                  }

                  {filtersDataExist && filtersData.vehicle.segments.length > 0 &&
                    <FloatContainer>
                      <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                        <InputLabel id="demo-simple-select-standard-label">{t('vehicleSegment')}</InputLabel>
                        <SelectField
                            value={vehicleSegments}
                            onChange={(e) => setVehicleSegments(e.target.value)}
                            multiple
                            renderValue={(selected) => renderValueHandler(selected, filtersData.vehicle.segments)}
                        >
                          {filtersData.vehicle.segments.map((segment) => (
                              <MenuItem value={segment.id} key={`segment_${segment.id}`}>
                                <Checkbox checked={vehicleSegments.indexOf(segment.id) > -1} />
                                {segment.name}
                              </MenuItem>
                          ))}
                        </SelectField>
                      </FormControl>
                    </FloatContainer>
                  }

                  {filtersDataExist &&
                    <FloatContainer>
                      <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                        <InputLabel id="demo-simple-select-standard-label">{t('vehicleModel')}</InputLabel>
                        <SelectField
                            value={vehicleModels}
                            onChange={(e) => setVehicleModels(e.target.value)}
                            multiple
                            renderValue={(selected) => renderValueHandler(selected, filtersData.vehicle.models, true)}
                        >
                          {vehicleModelsValues.length > 0 && vehicleModelsValues.map((model, i) => (
                              <MenuItem value={model.model} key={`group_${model.model}_${i}`}>
                                <Checkbox checked={vehicleModels.indexOf(model.model) > -1} />
                                {model.model}
                              </MenuItem>
                          ))}
                        </SelectField>
                      </FormControl>
                    </FloatContainer>
                  }
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                  <TypographyS>{t('typeOfOrder')}</TypographyS>
                </AccordionSummary>
                <AccordionDetails>
                  {filtersDataExist && filtersData.workshopTypes.length > 0 &&
                    <FloatContainer>
                      <FormControl variant="standard" sx={{ width: 'calc(100% - 1px)' }}>
                        <InputLabel id="demo-simple-select-standard-label">{t('workshopType')}</InputLabel>
                        <SelectField
                            value={workshopTypes}
                            onChange={(e) => setWorkshopTypes(e.target.value)}
                            multiple
                            renderValue={(selected) => renderValueHandler(selected, filtersData.workshopTypes)}
                        >
                          {filtersData.workshopTypes.map((workshop) => (
                              <MenuItem value={workshop.id} key={`workshop_${workshop.id}`}>
                                <Checkbox checked={workshopTypes.indexOf(workshop.id) > -1} />
                                {workshop.name}
                              </MenuItem>
                          ))}
                        </SelectField>
                      </FormControl>
                    </FloatContainer>
                  }
                </AccordionDetails>
              </Accordion>
            </>
          }

          <CustomButton type="submit" text={t('filtersButton')} onClickFnc={onSubmit} icon={isLoading ? <Loader/> : ''} />
          <CustomButtonEmpty text={t('clearFilters')} icon={<CloseIcon />} variant="text" onClickFnc={clearFiltersHandler} />
        </Form>
      </Container>
  );
};

export default ReportFilters;
